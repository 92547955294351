import React, { useEffect } from 'react';
import { Button, Layout, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';

const { Content } = Layout;

interface IProps {
    match: any;
}

const ErrorPage = (props: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const setUser = useStore((state) => state.setUser);
    const user = useStore((state) => state.user);
    let status;

    switch (props.match.params.status) {
        case '403':
        case '404':
        case '500':
            status = props.match.params.status;
            break;
        case '401':
            status = 403;
            break;
        case undefined:
            status = 404;
            break;
        default:
            status = 'error';
            break;
    }

    const clearUserInfo = () => {
        if (props.match.params.status === '401' && user.uid) {
            setUser({});
        }
    };

    useEffect(() => {
        setLoading(false);
        clearUserInfo();
    }, []);

    return (
        <Content className="site-layout-background error-container center">
            <Result
                status={status}
                title={t(props.match.params.title) || t('errorMessages.pageNotFound')}
                subTitle={t(props.match.params.message) || t('errorMessages.pageNotFoundText')}
                extra={
                    <Button onClick={() => (window.location.href = '/login')} type={'primary'} title={t('commonClass.goLogin')}>
                        {t('commonClass.goLogin')}
                    </Button>
                }
            />
        </Content>
    );
};

export default ErrorPage;
