import React, { useState } from 'react';
import { Button, message, Modal, Select, Upload } from 'antd';
import styles from 'assets/styles/modules/invoices.module.scss';
import { Company } from 'js/constants/types/company';
import BillingServices from 'js/data/billing-services';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InvoiceSearch } from 'js/constants/types/search';

const { Option } = Select;

interface IProps {
    isVisible: boolean;
    companies: Company[];
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoicesModal: React.FC<IProps> = ({ isVisible, companies, setIsVisible }: IProps) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [company, setCompany] = useState<number>();
    const [uploading, setUploading] = useState<boolean>(false);

    const props = {
        onRemove: (file) => {
            setFiles(() => {
                const index = files.indexOf(file);
                const newFileList = files.slice();
                newFileList.splice(index, 1);

                return newFileList;
            });
        },
        beforeUpload: (file) => {
            setFiles(() => [...files, file]);
            return false;
        },
        files,
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', files[0]);

        setUploading(true);
        const response = await BillingServices.uploadFile(company!, formData);

        if (!(response instanceof Response)) {
            setFiles([]);
            setIsVisible(false);
            message.success(t('invoices.uploadedSuccessfully'));
        }


        setUploading(false);
    };

    return (
        <Modal
            className={styles.modal}
            title={t('invoices.upload')}
            visible={isVisible}
            footer={null}
            width={300}
            onCancel={() => setIsVisible(false)}
        >
            <p className={styles.description}>{t('invoices.selectFileDescription')}</p>
            <Select className={styles.modalSelect} placeholder="Companies" onChange={(companyId: number) => setCompany(companyId)}>
                {companies.map((company) => {
                    return (
                        <Option key={company.companyId} value={company.companyId}>
                            {company.companyName}
                        </Option>
                    );
                })}
            </Select>
            <Upload {...props} maxCount={1}>
                <Button className={styles.modalUpload} icon={<UploadOutlined />}>
                    {t('invoices.selectFile')}
                </Button>
            </Upload>
            <Button
                className={styles.modalButton}
                type="primary"
                onClick={handleUpload}
                disabled={files.length === 0 || !company}
                loading={uploading}
            >
                {uploading ? t('invoices.uploading') : t('invoices.startUpload')}
            </Button>
        </Modal>
    );
};

export default InvoicesModal;
