import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { Company } from 'js/constants/types/company';
import FlowServices from 'js/data/flow-services';
import { useStore } from 'store';
import { AgencyFlow } from 'js/constants/types/flow';
import { AgenciesFlowSearch } from 'js/constants/types/search';

const { Option } = Select;

interface IProps {
    setSelectedCompany: (value: React.SetStateAction<number | undefined>) => void;
    setAgenciesFlows: (value: React.SetStateAction<AgencyFlow[]>) => void;
    form: FormInstance<any>;
}

const AgenciesFlowFilters = (props: IProps) => {
    const { t } = useTranslation();
    const [searchDisable, setSearchDisable] = useState<boolean>(true);
    const setLoading = useStore((state) => state.setLoading);
    const [companies, setCompanies] = useState<Company[]>([]);

    const getAgenciesFlow = async (formValues: AgenciesFlowSearch) => {
        const agencies = await FlowServices.getAgenciesFlow(formValues);
        const error = agencies instanceof Response;

        if (!error) {
            props.setAgenciesFlows(agencies);
        } else {
            props.setAgenciesFlows([]);
        }
    };

    const onSubmit = async (formValues: AgenciesFlowSearch) => {
        setLoading(true);
        props.setSelectedCompany(formValues.companyId);
        await getAgenciesFlow(formValues);
        setLoading(false);
    };


    const checkSearchInputInfo = (changedValues, allValues: AgenciesFlowSearch) => {
        let i = 0;

        for (const property in allValues) {
            if (allValues[property]) {
                i++;
            }
        }
        if (i >= 1) {
            setSearchDisable(false);
        } else {
            setSearchDisable(true);
        }
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Form form={props.form} layout="vertical" onFinish={onSubmit} onValuesChange={checkSearchInputInfo}>
            <Row gutter={20} align="bottom">
                <Col span={4}>
                    <Form.Item name="companyId" label={t('agenciesFlows.company')} rules={[{ required: true, message: '' }]}>
                        <Select>
                            {companies.map((company) => {
                                return (
                                    <Option key={company.companyId} value={company.companyId}>
                                        {company.companyName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name="agencyCode" label={t('agenciesFlows.code')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name="vat" label={t('agenciesFlows.vat')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="businessName" label={t('agenciesFlows.businessName')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item>
                        <Button className={styles.search} type="primary" htmlType="submit" disabled={searchDisable}>
                            {t('agenciesFlows.search')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default React.memo(AgenciesFlowFilters);
