import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styles from 'assets/styles/modules/messages-bar-chart.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    deliverToClient: MessagesTimeSlot[];
}

const MessagesBarChart = (props: IProps) => {
    const { t } = useTranslation();
    const [slots, setSlots] = useState<MessagesTimeSlot[]>([]);
    const SLOT_INTERVAL = 15;

    const calculateFormattedTime = (index: number) => {
        const date = new Date(new Date().setHours(0, 0, 0, 0));
        date.setMinutes(index * SLOT_INTERVAL);
        const formattedHours = ('0' + date.getHours()).slice(-2);
        const formattedMinutes = ('0' + date.getMinutes()).slice(-2);

        return `${formattedHours}:${formattedMinutes}`;
    };

    const getLowestSlaValue = () => {
        const increaseNumber = 5; // Random number to see the lowest bar.
        let lowestValue = 100;

        slots.forEach((slot) => {
            const low = slot.sla60! > slot.sla240! ? slot.sla240 : slot.sla60;
            if (low! < lowestValue) {
                lowestValue = low!;
            }
        });

        return lowestValue - increaseNumber;
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles['tooltip-line-chart']}>
                    <p className="label">
                        {`${t('messagesCharts.id')}: `} <b>{payload[0].payload.id}</b>
                    </p>
                    <p className="label">
                        {`${t('messagesCharts.slot')}: `} <b>{payload[0].payload.time}</b>
                    </p>
                    <p className="label">
                        {`${t('messagesCharts.sla60')}: `} <b>{`${payload[0].payload.sla60}%`}</b>
                    </p>
                    <p className="label">
                        {`${t('messagesCharts.sla240')}: `} <b>{`${payload[0].payload.sla240}%`}</b>
                    </p>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        const calculateSla = () => {
            const newSlots: MessagesTimeSlot[] = [];

            props.deliverToClient.map((item, index) => {
                const sla60 = ((item.total - item.sla60!) * 100) / item.total;
                const sla240 = ((item.total - item.sla240!) * 100) / item.total;
                const formattedTime = calculateFormattedTime(index);

                newSlots.push({
                    id: item.id,
                    total: item.total,
                    meanTime: item.meanTime,
                    sla60: Math.round(sla60),
                    sla240: Math.round(sla240),
                    time: formattedTime,
                });
            });

            setSlots(newSlots);
        };

        calculateSla();
    }, [props.deliverToClient]);

    return (
        <div className={styles['messages-bar-chart']}>
            <h4 className={styles['messages-bar-chart__title']}>Messages Bar Chart</h4>

            <ResponsiveContainer width="100%" aspect={5}>
                <BarChart width={730} height={250} data={slots}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[() => getLowestSlaValue(), 100]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="sla60" fill={styles.primary} />
                    <Bar dataKey="sla240" fill={styles.secondary} />
                    <ReferenceLine y={98} stroke="gray" />
                    <ReferenceLine y={90} stroke="gray" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default MessagesBarChart;
