import React from 'react';
import styles from 'assets/styles/modules/agencies-flow-management.module.scss';
import { Button, Space, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { AgencyFlow } from 'js/constants/types/flow';
import i18n from 'js/tools/i18n';
import { EditOutlined } from '@ant-design/icons';
import { FileType } from 'js/constants/enum/fileType';
import DownoadFileButtons from '../donwload-file';

interface IProps {
    agenciesFlow: AgencyFlow[];
    onEdit: any;
    downloadFile: (event, fileType: FileType) => Promise<void>;
}

const AgenciesFlowTable = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    const buttonsDownload = [
        {
            type: FileType.Csv,
            text: 'csv',
            disabled: props.agenciesFlow ?  false : true,
            tooltip: t('commonClass.downloadCsv'),
            order: 10,
            key: 1,
        },
        {
            type: FileType.Excel,
            text: 'xlsx',
            disabled: props.agenciesFlow ? false : true,
            tooltip: t('commonClass.downloadXlsx'),
            order: 20,
            key: 2,
        },
    ];
    const columns = [
        {
            title: t('agenciesFlows.code'),
            dataIndex: 'agencyCode',
            key: 1,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.businessName'),
            dataIndex: 'businessName',
            key: 18,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.name'),
            dataIndex: 'agencyName',
            key: 2,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.contact'),
            dataIndex: 'agencyContact',
            key: 3,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.address'),
            dataIndex: 'address',
            key: 4,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.postalCode'),
            dataIndex: 'postalCode',
            key: 5,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.city'),
            dataIndex: 'city',
            key: 6,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.province'),
            dataIndex: 'province',
            key: 7,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.country'),
            dataIndex: 'country',
            key: 8,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.phone1'),
            dataIndex: 'phone1',
            key: 9,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.phone2'),
            dataIndex: 'phone2',
            key: 10,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.email'),
            dataIndex: 'email',
            key: 12,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.vat'),
            dataIndex: 'vat',
            key: 17,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.statusDescription'),
            dataIndex: 'statusDescription',
            key: 13,
            align: 'center' as const,
        },
        {
            title: t('agenciesFlows.edit'),
            key: 19,
            align: 'center' as const,
            render: (record: AgencyFlow) => (
                <Space size="middle">
                    <Button onClick={(event) => props.onEdit(event, record)} type="primary" shape="circle" icon={<EditOutlined />} />
                </Space>
            ),
        },
    ];

    return (
        <>
            <DownoadFileButtons buttonsProperty={buttonsDownload} downloadFile={props.downloadFile} />

            <section className={styles.table}>
                <Table
                    columns={columns}
                    dataSource={props.agenciesFlow}
                    rowKey="key"
                    pagination={{ pageSize: 10, showSizeChanger: false, position: ['bottomRight'] }}
                    size="small"
                    scroll={{ x: 800 }}
                    footer={() => (
                        <div className={styles.footer}>
                            {t('commonClass.total')}: {props.agenciesFlow?.length || 0}
                        </div>
                    )}
                ></Table>
            </section>
        </>
    );
};

export default AgenciesFlowTable;
