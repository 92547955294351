import React from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from 'store';

const AuthRouter = ({ children, requiredRoles }: { children: JSX.Element; requiredRoles: number[] }) => {
    const user = useStore((state) => state.user);

    const userHasRequiredRole = () => {
        const rolesRestriction = requiredRoles.length !== 0;
        const hasRequiredRoles = requiredRoles.some((x) => user.roles?.includes(x));

        return !rolesRestriction || hasRequiredRoles;
    };

    if (!user?.uid) {
        return <Redirect to={{ pathname: '/login' }} />;
    }

    if (!userHasRequiredRole()) {
        return <Redirect to="/error/errorMessages.userUnauthorized&errorMessages.userUnauthorizedText&403" />;
    }

    return children;
};

export default AuthRouter;
