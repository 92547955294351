import { FileType } from 'js/constants/enum/fileType';
import { Report, ReportType } from 'js/constants/types/report';
import { ReportSearch } from 'js/constants/types/search';
import UtilsServices from 'js/tools/utils-services';
import { useStore } from 'store';

/*
 *  Shared variables and methods
 */
const token = useStore.getState().user?.token;
const headers = new Headers({ Authorization: `Bearer ${token}`, 'content-type': 'application/json' });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

useStore.subscribe((state) => {
    headers.set('Authorization', `Bearer ${state.user?.token}`);
});

export default class ReportServices {
    public static getReports = async (type: string): Promise<Report[]> => {
        const response = await fetch(`${baseUrl}/API/Reports/GetReports?type=${type}`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getInsuranceReports = async (): Promise<Report[]> => {
        const response = await fetch(`${baseUrl}/API/Reports/GetReports?type=${'ASSICURAZIONI_NEW'}`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getReportTypes = async (): Promise<ReportType[]> => {
        const response = await fetch(`${baseUrl}/API/Reports/GetReportTypes`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getReportData = async (reportSearch: ReportSearch): Promise<any[]> => {
        const response = await fetch(`${baseUrl}/API/Reports/GetReportData`, { 
            method: 'POST',
            headers,
            body: JSON.stringify(reportSearch)
        });        
        
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static  getReportDataFile = async (filename: string, fileType: FileType, reportSearch: ReportSearch) => {
        reportSearch.fileName = filename;
        reportSearch.fileType = fileType;
        const response = await fetch(`${baseUrl}/API/Reports/GetReportDataFile`, { 
            method: 'POST',
            headers,
            body: JSON.stringify(reportSearch)
        });        
       
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const temporaryLink = document.createElement('a');

        temporaryLink.href = url;
        switch (fileType){
            case FileType.Csv:
                filename = filename + '.csv';
                break;
            case FileType.Excel:
                filename = filename + '.xlsx';
                break;
        }
       
        temporaryLink.download = filename;
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        temporaryLink.remove();        
      }
}