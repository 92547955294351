import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import UtilsServices from 'js/tools/utils-services';
import { useStore } from 'store';

/*
 *  Shared variables and methods
 */
const token = useStore.getState().user?.token;
const headers = new Headers({ Authorization: `Bearer ${token}`, 'content-type': 'application/json' });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

useStore.subscribe((state) => {
    headers.set('Authorization', `Bearer ${state.user?.token}`);
});

/*
 *  Services
 */
export default class DeviceServices {
    public static getFromDeviceToFe = async (): Promise<MessagesTimeSlot[]> => {
        const response = await fetch(`${baseUrl}/API/Messages/FromDeviceToFe`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFromFeToDeliver = async (): Promise<MessagesTimeSlot[]> => {
        const response = await fetch(`${baseUrl}/API/Messages/FromFeToDeliver`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFromDeliverToClient = async (): Promise<MessagesTimeSlot[]> => {
        const response = await fetch(`${baseUrl}/API/Messages/FromDeliverToClient`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };
}
