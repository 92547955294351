import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Modal, Row, message, DatePicker, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AgencyFlow } from 'js/constants/types/flow';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import FlowServices from 'js/data/flow-services';
import { AGENCY_FLOW_PROPERTIES, fieldsAgencyProperty } from 'js/constants/resources/agency-flow-properties';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { IndexRoute } from 'react-router';
import { Utils } from 'js/tools/utils';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    selectedAgencyFlow: AgencyFlow;
    data: AgencyFlow[];
    refreshFlows: () => void;
    selectedCompany: number | undefined;
}

const AgenciesFlowEditSidebar = (props: IProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);
    const [flowData, setFlowData] = useState<AgencyFlow>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const currentLanguage = i18n.language;

    const onSubmit = async (flow: AgencyFlow) => {
        setFlowData(flow);
        setModalVisible(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        await updateFlow();
        setModalVisible(false);
        setConfirmLoading(false);
    };

    const updateFlow = async () => {
        setLoading(true);
        const response = await FlowServices.agencyUpdateFlow(flowData!);
        setLoading(false);

        if (!(response instanceof Response)) {
            message.success(t('agenciesFlows.updateSuccess'));
        }

        props.refreshFlows();
    };

    useEffect(() => {
        form.setFieldsValue(props.selectedAgencyFlow);
    }, [props.selectedAgencyFlow]);

    return (
        <Drawer
            title={'Edit'}
            placement="right"
            getContainer={false}
            closeIcon={<FontAwesomeIcon icon={faTimes} className="menu-icon anticon" color={'#1890ff'} />}
            width={window.innerWidth > 1020 ? 540 : '85%'}
            onClose={() => {
                props.setVisible(false);
            }}
            visible={props.visible}
        >
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Row gutter={[16, 7]}>
                    {fieldsAgencyProperty
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((p) => {
                            switch (p.type) {
                                case 'text':
                                    return (
                                        <Col key={p.order} span={12} style={AGENCY_FLOW_PROPERTIES?.get(p.key) ? {} : { display: 'none' }}>
                                            <Form.Item label={AGENCY_FLOW_PROPERTIES?.get(p.key)} name={p.key}>
                                                <Input readOnly={p.readonly} />
                                            </Form.Item>
                                        </Col>
                                    );
                                    break;
                                case 'date':
                                    return (
                                        <Col key={p.order} span={12} style={AGENCY_FLOW_PROPERTIES?.get(p.key) ? {} : { display: 'none' }}>
                                            <Form.Item
                                                label={AGENCY_FLOW_PROPERTIES?.get(p.key)}
                                                name={p.key}
                                                getValueProps={(i) => ({ value: moment( ) })}
                                            >
                                                {p.readonly ? (
                                                    <DatePicker format={DateFormats[currentLanguage]} disabled />
                                                ) : (
                                                    <DatePicker format={DateFormats[currentLanguage]} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    );

                                    break;
                            }
                        })}
                </Row>
                <Form.Item>
                    <Button className={styles['edit-button']} type="primary" htmlType="submit">
                        {t('agenciesFlows.save')}
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                title={t('agenciesFlows.confirmUpdateTitle')}
                visible={modalVisible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => setModalVisible(false)}
                zIndex={999999}
                okText={t('agenciesFlows.confirm')}
                cancelText={t('agenciesFlows.cancel')}
                getContainer={false}
            >
                <p>{t('agenciesFlows.confirmUpdateText')}</p>
            </Modal>
        </Drawer>
    );
};

export default AgenciesFlowEditSidebar;
