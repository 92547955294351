import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadActionButton } from 'js/constants/types/action-button';
import { FileType } from 'js/constants/enum/fileType';

interface IProps {
    buttonsProperty: DownloadActionButton[];
    downloadFile: (event, fileType: FileType) => void;
}

const DownoadFileButtons = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <section>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                {props.buttonsProperty
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((p) => {
                        return (
                            <Tooltip key={p.key} placement="left" title={t(p.tooltip == null ? '' : p.tooltip)}>
                                <Button
                                    type="default"
                                    className={[p.disabled ? 'btn-download-file-disabled' : 'btn-download-file'].join(' ')}
                                    shape="round"
                                    icon={<FontAwesomeIcon icon={p.type == 1 ? faFileExcel : faFileCsv} className="anticon" />}
                                    size="large"
                                    disabled={p.disabled}
                                    onClick={(event) => props.downloadFile(event, p.type)}
                                >
                                    {p.text}
                                </Button>
                            </Tooltip>
                        );
                    })}
            </Space>
        </section>
    );
};

export default DownoadFileButtons;
