import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Company } from 'js/constants/types/company';
import FlowServices from 'js/data/flow-services';
import { DateFormats, StringFormats } from 'js/config/config';
import { Utils } from 'js/tools/utils';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { useStore } from 'store';
import { FlowItem } from 'js/constants/types/flow';
import { useLocation } from 'react-router-dom';

const { Option } = Select;
const utils = new Utils();
const { shortDateFormatUS } = StringFormats;

interface IProps {
    setSelectedCompany: (value: React.SetStateAction<number | undefined>) => void;
    setFlows: (value: React.SetStateAction<FlowItem[]>) => void;
    form: FormInstance<any>;
}

const InputFlowFilters = (props: IProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const currentLanguage = i18n.language;
    const setLoading = useStore((state) => state.setLoading);
    const [companies, setCompanies] = useState<Company[]>([]);

    const initialValues = {
        insertDate: moment(),
        onlyErrors: false,
        onElaboration: false,
    };

    const onSubmit = async (value: {
        companyId: number;
        insertDate: moment.Moment;
        licensePlate: string;
        customerName: string;
        onlyErrors: boolean;
        onElaboration: boolean;
    }) => {
        setLoading(true);

        const insertDate = value.insertDate?.format(shortDateFormatUS);
        props.setSelectedCompany(value.companyId);
        props.setFlows(
            await FlowServices.getFlowsMovements(value.companyId, insertDate, value.licensePlate, value.customerName, value.onlyErrors, value.onElaboration)
        );

        setLoading(false);
    };

    const loadFlowsMovements = async () => {
        if (location.state) {
            const companyId = location.state.companyId;
            const licensePlate = location.state.licensePlate;
            const customerName = location.state.customerNamr;
            const insertDate = location.state.insertDate;
            const onlyErrors = location.state.onlyErrors;
            const onElaboration = false;

            props.form.setFieldsValue({
                companyId,
                onlyErrors,
                insertDate: moment(new Date(insertDate)),
            });

            props.setFlows(
                await FlowServices.getFlowsMovements(companyId, insertDate, licensePlate, customerName, onlyErrors, onElaboration)
            );
            props.setSelectedCompany(companyId);
        }
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    useEffect(() => {
        loadFlowsMovements();
    }, [location.state]);

    useEffect(() => {
        getCompanies();
        loadFlowsMovements();
    }, []);

    return (
        <Form form={props.form} layout="vertical" onFinish={onSubmit} initialValues={initialValues}>
            <Row gutter={20} align="bottom">
                <Col span={4}>
                    <Form.Item name="companyId" label={t('flows.company')} rules={[{ required: true, message: '' }]}>
                        <Select>
                            {companies.map((company) => {
                                return (
                                    <Option key={company.companyId} value={company.companyId}>
                                        {company.companyName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item name="insertDate" label={t('flows.date')} rules={[{ required: true }]}>
                        <DatePicker
                            className={styles['date-picker']}
                            format={DateFormats[currentLanguage]}
                            disabledDate={utils.DisableDateOfFuture}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name="customerName" label={t('flows.customerName')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item name="licensePlate" label={t('flows.licensePlate')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item name="onlyErrors" label={t('flows.errors')} valuePropName="checked">
                        <Checkbox>{t('flows.onlyErrors')}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="onElaboration"
                        label={
                            <div>
                                {t('flows.onElaboration')}
                                <Tooltip title={t('flows.onElaborationTooltip')}>
                                    <InfoCircleOutlined className={styles.tooltip} />
                                </Tooltip>
                            </div>
                        }
                        valuePropName="checked"
                    >
                        <Checkbox>{t('flows.onlyElaboration')}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <Button className={styles.search} type="primary" htmlType="submit">
                            {t('flows.search')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default React.memo(InputFlowFilters);
