import React from 'react';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MenuItem } from 'js/constants/types/menuItem';

interface IProps {
    menuList: MenuItem[];
}

const BreadCrumb = (props: IProps) => {
    const { t } = useTranslation();
    const location = useLocation();

    const getActivePath = (menuItems: MenuItem[]) => {
        const currentPath = location.pathname;

        for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].url === currentPath) {
                return menuItems[i];
            }

            if (menuItems[i].subMenu.length) {
                const activeItem = getActivePath(menuItems[i].subMenu);

                if (activeItem) {
                    return activeItem;
                }
            }
        }
    };

    const renderBreadCrumb = () => {
        const homePath = '/';
        const currentPath = location.pathname;
        const menuItem = getActivePath(props.menuList);

        if (currentPath === homePath) {
            return;
        }

        if (menuItem) {
            return (
                <Breadcrumb.Item>
                    <a href={menuItem.url}>{menuItem.label}</a>
                </Breadcrumb.Item>
            );
        }
    };

    return (
        <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
                <a href="/">{t('commonClass.homepage')}</a>
            </Breadcrumb.Item>
            {renderBreadCrumb()}
        </Breadcrumb>
    );
};

export default BreadCrumb;
