import { Button, Calendar, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/modules/input-flow-dashboard.module.scss';
import { FlowAnalytics } from 'js/constants/types/flow';
import moment from 'moment';
import { DateFormats, StringFormats } from 'js/config/config';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { Company } from 'js/constants/types/company';
import { Link } from 'react-router-dom';
import i18n from 'js/tools/i18n';
import 'moment/locale/it';
import FlowServices from 'js/data/flow-services';

const period = 3;
const { shortDateFormatUS } = StringFormats;

const InputFlowDashboard = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const currentLanguage = i18n.language;
    const setLoading = useStore((state) => state.setLoading);
    const today = moment();
    const oneYearAgo = moment().subtract(1, 'years');
    const [companies, setCompanies] = useState<Company[]>([]);
    const [analitycs, setAnalytics] = useState<FlowAnalytics[]>([]);
    const [days, setDays] = useState<moment.Moment[]>([moment().subtract(2, 'days'), moment().subtract(1, 'days'), moment()]);

    const getFlowsAnalytics = async (date?: string) => {
        const bodyRequest = { startDate: date || null, period: period };
        setLoading(true);
        setAnalytics(await FlowServices.getFlowsAnalytics(bodyRequest));
        setLoading(false);
    };

    const onSubmit = (value: { day: moment.Moment }) => {
        setDays([moment(value.day).subtract(2, 'days'), moment(value.day).subtract(1, 'days'), moment(value.day)]);
        getFlowsAnalytics(value.day.format(shortDateFormatUS));
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    useEffect(() => {
        getCompanies();
        getFlowsAnalytics();
    }, []);

    return (
        <>
            <h2 className={styles.title}>{t('flows.dashboard')}</h2>
            <p>{t('flows.dashboardDescription')}</p>
            <Row gutter={20}>
                <Col xs={24} sm={24} lg={15} xl={17}>
                    {companies.map((company) => {
                        const item = analitycs.find((x) => x.companyId === company.companyId);

                        return (
                            <section key={company.companyId} className={styles['company-wrapper']}>
                                <h4 className={styles.company}>{company.companyName}</h4>
                                <Row gutter={[30, 30]}>
                                    {days.map((day, index) => {
                                        const dayAnalytics = item?.analytics.find((x) => moment(x.fileDate).isSame(day, 'days'));
                                        const dayString = day.locale(currentLanguage.split('-')[0]).format('dddd');
                                        const selectedDay = index === days.length - 1;

                                        if (dayAnalytics) {
                                            return (
                                                <Col xs={24} sm={8} lg={8} xl={8} className={styles['day-col']} key={dayAnalytics.fileDate}>
                                                    <div className={styles['day-box']}>
                                                        <h4 className={`${styles['day-title']} ${selectedDay ? 'selected' : ''}`}>
                                                            <span>{dayString}</span>
                                                            <span>{day.format(DateFormats[currentLanguage])}</span>
                                                        </h4>
                                                        <Link
                                                            className={styles['day-item']}
                                                            to={{
                                                                pathname: '/input-flow-management',
                                                                state: {
                                                                    companyId: company.companyId,
                                                                    insertDate: day.format(shortDateFormatUS),
                                                                    onlyErrors: false,
                                                                },
                                                            }}
                                                        >
                                                            <span className={styles['day-item-label']}>{t('flows.importedRows')}</span>
                                                            <strong>{dayAnalytics.rowsImported}</strong>
                                                        </Link>
                                                        <Link
                                                            className={`${styles['day-item']} ${
                                                                dayAnalytics.elaborationErrors > 0 ? styles.error : ''
                                                            }`}
                                                            to={{
                                                                pathname: '/input-flow-management',
                                                                state: {
                                                                    companyId: company.companyId,
                                                                    insertDate: day.format(shortDateFormatUS),
                                                                    onlyErrors: true,
                                                                },
                                                            }}
                                                        >
                                                            <span className={styles['day-item-label']}>{t('flows.importErrors')}</span>
                                                            <strong>{dayAnalytics.elaborationErrors}</strong>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            );
                                        } else {
                                            return (
                                                <Col xs={24} sm={8} lg={8} xl={8} className={styles['day-col']} key={day.toLocaleString()}>
                                                    <div className={styles['day-box']}>
                                                        <h4 className={`${styles['day-title']} ${selectedDay ? styles['selected'] : ''}`}>
                                                            <span>{dayString}</span>
                                                            <span>{day.format(DateFormats[currentLanguage])}</span>
                                                        </h4>
                                                        <div className={styles['day-item-nodata']}>
                                                            <span>{t('flows.noFilesImported')}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        }
                                    })}
                                </Row>
                            </section>
                        );
                    })}
                </Col>
                <Col xs={24} sm={24} lg={9} xl={7}>
                    <aside className={styles['sidebar-wrapper']}>
                        <h4>{t('flows.selectDate')}</h4>
                        <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ ['day']: moment() }}>
                            <Form.Item name="day">
                                <Calendar validRange={[oneYearAgo, today]} fullscreen={false} />
                            </Form.Item>
                            <Form.Item>
                                <Button className={styles.submit} type="primary" htmlType="submit">
                                    {t('flows.search')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </aside>
                </Col>
            </Row>
        </>
    );
};

export default InputFlowDashboard;
