import { Button, Input, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import { FlowItem } from 'js/constants/types/flow';
import { EditOutlined, FileTextOutlined, SyncOutlined } from '@ant-design/icons';
import { MovementStatus } from 'js/constants/enum/movementStatus';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertLevel } from 'js/constants/enum/alertLevel';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FlowPairing } from 'js/constants/enum/flowPairing';

interface IProps {
    flows: FlowItem[];
    onEdit: any;
    onReSyncMov: any;
    openDrawer: any;
    openNotes: any;
}

const FlowTable = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<any>('');
    let searchInput: any;

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: (x: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={x.selectedKeys[0]}
                    onChange={(e) => x.setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])}
                    onPressEnter={() => handleSearch(x.selectedKeys, x.confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(x.selectedKeys, x.confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(x.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            x.confirm({ closeDropdown: false });
                            setSearchText(x.selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    ></Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const mapMovementStatusClasses = (status: number) => {
        switch (status) {
            case MovementStatus.Open:
                return 'status-open';

            case MovementStatus.Closed:
                return 'status-closed';

            default:
                return 'status-noaction';
        }
    };

    const columns = [
        {
            title: t('flows.status'),
            dataIndex: 'movementStatus',
            key: 1,
            render: (status: number, flow: FlowItem) => (
                <Button
                    className={mapMovementStatusClasses(status)}
                    type="primary"
                    shape="circle"
                    size="small"
                    onClick={(event) => props.openNotes(event, flow)}
                    icon={<FileTextOutlined />}
                />
            ),
        },
        {
            title: t('flows.policyNumber'),
            dataIndex: 'policyNumber',
            key: 2,
            align: 'center' as const,
            ...getColumnSearchProps('policyNumber'),
        },
        {
            title: t('flows.voucherNumber'),
            dataIndex: 'voucherNumber',
            key: 3,
            ...getColumnSearchProps('voucherNumber'),
        },
        {
            title: t('flows.movementId'),
            dataIndex: 'movementId',
            key: 4,
            ...getColumnSearchProps('movementId'),
        },
        {
            title: t('flows.movementType'),
            dataIndex: 'movementType',
            key: 5,
            align: 'center' as const,
        },
        {
            title: t('flows.customerName'),
            dataIndex: 'customerName',
            key: 6,
            align: 'center' as const,
            ...getColumnSearchProps('customerName'),
        },
        {
            title: t('flows.customerFiscalId'),
            dataIndex: 'customerFiscalId',
            key: 7,
            align: 'center' as const,
            ...getColumnSearchProps('customerFiscalId'),
        },
        {
            title: t('flows.licensePlate'),
            dataIndex: 'licensePlate',
            key: 8,
            align: 'center' as const,
            ...getColumnSearchProps('licensePlate'),
        },
        {
            title: t('flows.oldLicensePlate'),
            dataIndex: 'oldLicensePlate',
            key: 9,
            ...getColumnSearchProps('oldLicensePlate'),
        },
        {
            title: t('flowConstants.agencyCode'),
            dataIndex: 'agencyCode',
            key: 10,
            ...getColumnSearchProps('agencyCode'),
        },
        {
            title: t('flows.oldPolicyNumber'),
            dataIndex: 'oldPolicyNumber',
            key: 11,
            ...getColumnSearchProps('oldPolicyNumber'),
        },
        {
            title: t('flows.product'),
            dataIndex: 'product',
            key: 12,
            align: 'center' as const,
        },
        {
            title: t('flowConstants.policyDate'),
            dataIndex: 'policyDate',
            key: 13,
            align: 'center' as const,
        },
        {
            title: t('flows.startServiceDate'),
            dataIndex: 'startServiceDate',
            key: 14,
            align: 'center' as const,
            render: (date: string) => moment(new Date(date)).format(DateFormats[currentLanguage]),
        },
        {
            title: t('flows.endServiceDate'),
            dataIndex: 'endServiceDate',
            key: 15,
            align: 'center' as const,
            render: (date: string) => moment(new Date(date)).format(DateFormats[currentLanguage]),
        },
        {
            title: t('flows.insertDate'),
            dataIndex: 'insertDate',
            key: 16,
            align: 'center' as const,
            render: (date: string) => moment(date).format(DateFormats[currentLanguage]),
        },
        {
            title: t('flowConstants.city'),
            dataIndex: 'city',
            key: 17,
            align: 'center' as const,
        },
        {
            title: t('flowConstants.postalCode'),
            dataIndex: 'postalCode',
            key: 18,
            align: 'center' as const,
        },
        {
            title: t('flowConstants.province'),
            dataIndex: 'province',
            key: 19,
            align: 'center' as const,
        },
        {
            title: t('flows.action'),
            key: 21,
            align: 'center' as const,
            render: (record: FlowItem) =>
                record.outcome != FlowPairing.Missing ? (
                    <Tooltip placement="topLeft" title={t('commonClass.edit')}>
                        <Space size="middle">
                            <Button
                                onClick={(event) => props.onEdit(event, record)}
                                type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                            />
                        </Space>
                    </Tooltip>
                ) : (
                    <Tooltip placement="topLeft" title={t('commonClass.reSyncPairing')}>
                        <Space size="middle">
                            <Button onClick={(event) => props.onReSyncMov(event, record)} danger shape="circle" icon={<SyncOutlined />} />
                        </Space>
                    </Tooltip>
                ),
        },
    ];

    const addFlowRowClasses = (flow: FlowItem) => {
        if (!flow.spoolDate) {
            return 'flow-edited-row';
        }

        if (Number(flow.outcome) === FlowPairing.Error) {
            return 'flow-error-row';
        }

        if (Number(flow.outcome) === FlowPairing.Missing) {
            return 'flow-misspairing-row';
        }

        return '';
    };

    const mapResultIcons = (flowPairingResult: number) => {
        switch (flowPairingResult) {
            case FlowPairing.Success:
                return (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        title={AlertLevel[flowPairingResult]}
                        size="lg"
                        className={styles['green-icon']}
                    />
                );

            case FlowPairing.Error:
                return (
                    <FontAwesomeIcon icon={faTimesCircle} title={AlertLevel[flowPairingResult]} size="lg" className={styles['red-icon']} />
                );

            default:
                return '';
        }
    };

    return (
        <Table
            columns={columns}
            size="small"
            scroll={{ x: 800 }}
            pagination={{ pageSize: 20 }}
            dataSource={props.flows?.map((item, index) => ({
                ...item,
                key: index,
                outcome: item.outcome,
            }))}
            rowKey="key"
            rowClassName={(record) => addFlowRowClasses(record)}
            onRow={(record) => {
                return {
                    onClick: () => {
                        props.openDrawer(record);
                    },
                };
            }}
            footer={() => (
                <div className={styles.footer}>
                    {t('flows.total')}: {props.flows?.length || 0}
                </div>
            )}
        ></Table>
    );
};

export default FlowTable;
