import React, { useEffect, useState } from 'react';
import { Button, Form, FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Company } from 'js/constants/types/company';
import FlowServices from 'js/data/flow-services';
import { useStore } from 'store';
import styles from 'assets/styles/modules/invoices.module.scss';
import BillingServices from 'js/data/billing-services';
import { OutputFile } from 'js/constants/types/outputFile';
import { Bill } from 'js/constants/types/bill';
import { InvoiceSearch } from 'js/constants/types/search';

const { Option } = Select;

interface IProps {
    setSelectedCompany: (value: React.SetStateAction<number | undefined>) => void;
    setSelectedInvoice: (value: React.SetStateAction<Bill | undefined>) => void;
    setSelectedFile: (value: React.SetStateAction<OutputFile | undefined>) => void;
    getInvoices: (search: InvoiceSearch) => Promise<void>;
    form: FormInstance<any>;
}

const InvoicesFilters = (props: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [outputFiles, setOutputFiles] = useState<OutputFile[]>([]);
    const [searchDisable, setSearchDisable] = useState<boolean>(true);
    const [form] = Form.useForm();

    const initialValues = {
        companyId: null,
        fileName: null,
    };

    const onSubmit = async (formValues: InvoiceSearch) => {
        setLoading(true);
        props.setSelectedCompany(formValues.companyId);
        props.setSelectedFile(outputFiles.find((file) => file.invoiceNumber === formValues.fileName));
        await props.getInvoices(formValues);
        setLoading(false);
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    const onCompanyChange = (companyId: number) => {
        loadBillingFiles(companyId);
        setSelectedCompany(companyId);
        // Clear fileNameInput
        form.setFieldsValue({ fileName: undefined });
        setSearchDisable(true);
    };

    const onFileChange = () => {
        setSearchDisable(false);
    };

    const onFileFocus = () => {
        if (selectedCompany != undefined) loadBillingFiles(selectedCompany);
    };

    const loadBillingFiles = async (companyId: number) => {
        setLoading(true);
        const files = await BillingServices.getBillingFiles(companyId);
        setOutputFiles(files ? files : []);
        setLoading(false);
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Form className={styles.form} form={form} layout="horizontal" initialValues={initialValues} onFinish={onSubmit}>
            <p className={styles.description}>{t('invoices.selectCompany')}</p>
            <Form.Item name="companyId" rules={[{ required: true }]}>
                <Select className={styles.select} placeholder="Companies"  onChange={onCompanyChange}>
                    {companies.map((company) => {
                        return (
                            <Option key={company.companyId} value={company.companyId}>
                                {company.companyName}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <p className={styles.description}>{t('invoices.selectInvoice')}</p>
            <Form.Item name="fileName" rules={[{ required: true }]}>
                <Select className={styles.select} placeholder="Invoices" showSearch onFocus={onFileFocus} onChange={onFileChange}>
                    {outputFiles.map((file) => {
                        return (
                            <Option key={`${file.invoiceNumber}`} value={file.invoiceNumber}>
                                {file.invoiceNumber}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit" disabled={searchDisable}>
                {t('invoices.search')}
            </Button>
        </Form>
    );
};

export default React.memo(InvoicesFilters);
