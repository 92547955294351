import Layout from 'antd/lib/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Homepage = () => {
    const { t } = useTranslation();

    return (
        <Layout className="site-layout-background home-container center">
            <div>
                <h2 className="center title-content">{t('commonClass.welcomeMsg')}</h2>
            </div>
        </Layout>
    );
};

export default Homepage;
