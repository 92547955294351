import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import styles from 'assets/styles/modules/messages-numbers.module.scss';

interface IProps {
    deviceToFe: MessagesTimeSlot[];
    feToDeliver: MessagesTimeSlot[];
    deliverToClient: MessagesTimeSlot[];
}

const MessagesNumbers = (props: IProps) => {
    const [totalMessages, setTotalMessages] = useState<number>(0);
    const [averageDevice, setAverageDevice] = useState<number>(0);
    const [averageDeliver, setAverageDeliver] = useState<number>(0);
    const [averageClient, setAverageClient] = useState<number>(0);

    const calculateTotalMessages = () => {
        let total = 0;
        props.deviceToFe.map((slot) => {
            total += slot.total;
        });

        setTotalMessages(total);
    };

    const calculateAverage = (arr: MessagesTimeSlot[]) => {
        let sum = 0;

        arr.map((slot) => {
            sum += slot.meanTime;
        });

        return Math.round(sum / arr.length);
    };

    useEffect(() => {
        calculateTotalMessages();
        setAverageDevice(calculateAverage(props.deviceToFe));
        setAverageDeliver(calculateAverage(props.feToDeliver));
        setAverageClient(calculateAverage(props.deliverToClient));
    }, [props.deviceToFe, props.feToDeliver, props.deliverToClient]);

    return (
        <Row gutter={15} className={styles['messages-numbers']}>
            <Col span={12} style={{ marginBottom: 25 }}>
                <div className={styles['messages-numbers__box']}>
                    <span className={styles['messages-numbers__num']}>{totalMessages}</span>
                    <h4>
                        <span className={styles['messages-numbers__subtitle']}>Daily average</span>
                        Total messages
                    </h4>
                </div>
                <div className={styles['messages-numbers__box']}>
                    <span className={styles['messages-numbers__num']}>{averageDevice}s</span>
                    <h4>
                        <span className={styles['messages-numbers__subtitle']}>Daily average</span>
                        Device → Frontend
                    </h4>
                </div>
            </Col>
            <Col span={12} style={{ marginBottom: 25 }}>
                <div className={styles['messages-numbers__box']}>
                    <span className={styles['messages-numbers__num']}>{averageDeliver}s</span>
                    <h4>
                        <span className={styles['messages-numbers__subtitle']}>Daily average</span>
                        Frontend → Deliver
                    </h4>
                </div>

                <div className={styles['messages-numbers__box']}>
                    <span className={styles['messages-numbers__num']}>{averageClient}s</span>
                    <h4>
                        <span className={styles['messages-numbers__subtitle']}>Daily average</span>
                        Deliver → Client
                    </h4>
                </div>
            </Col>
        </Row>
    );
};

export default MessagesNumbers;
