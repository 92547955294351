import React, { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import { StringFormats } from 'js/config/config';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { FlowItem } from 'js/constants/types/flow';
import FlowViewSidebar from 'js/components/input-flow/flow-view-sidebar';
import FlowEditSidebar from 'js/components/input-flow/flow-edit-sidebar';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import FlowTable from 'js/components/input-flow/flow-table';
import FlowNotesModal from 'js/components/input-flow/flow-notes-modal';
import FlowServices from 'js/data/flow-services';
import InputFlowFilters from 'js/components/input-flow/flow-filters';

const { shortDateFormatUS } = StringFormats;

const InputFlowManagement = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);
    const [flows, setFlows] = useState<FlowItem[]>([]);
    const [visibleViewSidebar, setVisibleViewSidebar] = useState<boolean>(false);
    const [visibleEditSidebar, setVisibleEditSidebar] = useState<boolean>(false);
    const [visibleNotesModal, setVisibleNotesModal] = useState<boolean>(false);
    const [selectedFlow, setSelectedFlow] = useState<FlowItem>({});
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [editedFlows, setEditedFlows] = useState<boolean>(false);

    const onEdit = async (event: any, record: FlowItem) => {
        setLoading(true);
        event.stopPropagation();
        setSelectedFlow(await FlowServices.getFlowData(selectedCompany!, record.movementId!));
        setVisibleEditSidebar(true);

        setLoading(false);
    };

    const onResyncMovement = async (event: any, record: FlowItem) => {
        setLoading(true);
        event.stopPropagation();
        const response = await FlowServices.reSyncMovement(selectedCompany!, record.movementId!);

        if (!(response instanceof Response)) {
            message.success(t('flows.uploadedSuccessfully'));
        }
        setLoading(false);
    };

    const openDrawer = async (record: FlowItem) => {
        setLoading(true);

        setSelectedFlow(await FlowServices.getFlowData(selectedCompany!, record.movementId!));
        setVisibleViewSidebar(true);

        setLoading(false);
    };

    const refreshFlows = async () => {
        const formValues = form.getFieldsValue();
        const companyId = formValues.companyId;
        const customerName = formValues.customerName;
        const licensePlate = formValues.licensePlate;
        const insertDate = formValues.insertDate?.format(shortDateFormatUS);
        const onlyErrors = formValues.onlyErrors;
        const onElaboration = formValues.onElaboration;

        setLoading(true);
        setFlows(await FlowServices.getFlowsMovements(companyId, insertDate, licensePlate, customerName, onlyErrors, onElaboration));
        setLoading(false);
    };

    const confirmChanges = async () => {
        setLoading(true);
        await FlowServices.startElaborations(selectedCompany!);
        setLoading(false);
        refreshFlows();
    };

    const openNotes = async (event: any, flow: FlowItem) => {
        event.stopPropagation();
        setSelectedFlow(flow);
        setVisibleNotesModal(true);
    };

    useEffect(() => {
        setEditedFlows(flows?.some((value) => value.spoolDate === null));
    }, [flows]);

    return (
        <>
            <div className={styles.header}>
                <h2 className={styles.title}>{t('flows.management')}</h2>
            </div>
            <section className={styles.filters}>
                <InputFlowFilters setSelectedCompany={setSelectedCompany} setFlows={setFlows} form={form} />
            </section>
            <section className={styles.table}>
                {editedFlows && (
                    <div className={styles['process-info']}>
                        <p className={styles.text}>{t('flows.rowsEditedDescription')}</p>
                        <Button type="primary" onClick={() => confirmChanges()}>
                            {t('flows.processChanges')}
                        </Button>
                    </div>
                )}

                <FlowTable flows={flows} onEdit={onEdit} onReSyncMov={onResyncMovement} openDrawer={openDrawer} openNotes={openNotes} />
            </section>
            <FlowViewSidebar
                visible={visibleViewSidebar}
                setVisible={setVisibleViewSidebar}
                selectedFlow={selectedFlow}
                selectedCompany={selectedCompany}
                data={flows}
            />
            <FlowEditSidebar
                visible={visibleEditSidebar}
                setVisible={setVisibleEditSidebar}
                selectedFlow={selectedFlow}
                data={flows}
                refreshFlows={refreshFlows}
                selectedCompany={selectedCompany}
            />
            <FlowNotesModal
                visible={visibleNotesModal}
                setVisible={setVisibleNotesModal}
                selectedCompany={selectedCompany}
                selectedFlow={selectedFlow}
                refreshFlows={refreshFlows}
            />
        </>
    );
};

export default InputFlowManagement;
