import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styles from 'assets/styles/modules/messages-line-chart.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    deviceToFe: MessagesTimeSlot[];
    feToDeliver: MessagesTimeSlot[];
    deliverToClient: MessagesTimeSlot[];
}

const MessagesLineChart = (props: IProps) => {
    const { t } = useTranslation();
    const [slots, setSlots] = useState<MessagesTimeSlot[]>([]);
    const SLOT_INTERVAL = 15;

    const calculateFormattedTime = (index: number) => {
        const date = new Date(new Date().setHours(0, 0, 0, 0));
        date.setMinutes(index * SLOT_INTERVAL);
        const formattedHours = ('0' + date.getHours()).slice(-2);
        const formattedMinutes = ('0' + date.getMinutes()).slice(-2);

        return `${formattedHours}:${formattedMinutes}`;
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles['tooltip-line-chart']}>
                    <p className="label">
                        {`${t('messagesCharts.id')}: `} <b>{payload[0].payload.id}</b>
                    </p>
                    <p className="label">
                        {`${t('messagesCharts.slot')}: `} <b>{payload[0].payload.time}</b>
                    </p>
                    <p className="label">
                        {`${t('messagesCharts.time')}: `} <b>{`${payload[0].payload.meanTime}s`}</b>
                    </p>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        // TODO: Check if some package is missing in one of the three groups
        const calculateSlots = () => {
            const newSlots: MessagesTimeSlot[] = [];

            props.deviceToFe.map((item: MessagesTimeSlot, index: number) => {
                const deviceToFeTime = item.meanTime || 0;
                const feToDeliverTime = props.feToDeliver.find((x) => x.id === item.id)?.meanTime || 0;
                const deliverToClientTime = props.deliverToClient.find((x) => x.id === item.id)?.meanTime || 0;
                const totalMeanTime = deviceToFeTime + feToDeliverTime + deliverToClientTime;
                const formattedTime = calculateFormattedTime(index);

                newSlots.push({ id: item.id, total: 0, meanTime: totalMeanTime, time: formattedTime });
            });

            setSlots(newSlots);
        };

        calculateSlots();
    }, [props.deviceToFe, props.feToDeliver, props.deliverToClient]);

    return (
        <div className={styles['messages-line-chart']}>
            <h4 className={styles['messages-line-chart__title']}>Messages Line Chart</h4>

            <ResponsiveContainer width="100%" aspect={4.5}>
                <LineChart
                    width={1000}
                    height={300}
                    data={slots}
                    margin={{
                        top: 25,
                        right: 40,
                        left: 0,
                        bottom: 15,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <ReferenceLine y={60} stroke="#777777" />
                    <Line type="monotone" dataKey="meanTime" stroke={styles.primary} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default MessagesLineChart;
