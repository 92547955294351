import User from 'js/classes/user';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface IState {
    user: User;
    loading: boolean;
    setUser: (user: User) => void;
    setLoading: (loading: boolean) => void;
}

export const useStore = create<IState>(
    persist(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (set, get) => ({
            user: {},
            loading: false,
            setUser: (user) => set((state) => ({ ...state, user })),
            setLoading: (loading) => set((state) => ({ ...state, loading })),
        }),
        {
            name: 'viasatip',
            getStorage: () => sessionStorage,
        }
    )
);
