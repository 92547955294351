import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React from 'react';

interface IProps {
    color?: string;
    tooltipText?: string;
}

const Dot = (props: IProps) => {
    return (
        <Tooltip title={props.tooltipText}>
            <FontAwesomeIcon icon={faCircle} size="xs" color={props.color} />
        </Tooltip>
    );
};

export default Dot;
