import React, { useState } from 'react';
import { Alert, Button, Form, Input, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import UserServices from 'js/data/user-services';

const { Content } = Layout;
const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 4 },
};

const LoginForm = () => {
    const { t } = useTranslation();
    const setUser = useStore((state) => state.setUser);
    const setLoading = useStore((state) => state.setLoading);
    const [type, setType] = useState<any>(undefined);
    const [message, setMessage] = useState<any>(undefined);

    const onFinish = async (values: any) => {
        setLoading(true);
        const login = await UserServices.login(values);

        if (login instanceof Response) {
            if (login.status == 401) {
                document.getElementById('alert-login')!.hidden = false;
                setType('error');
                setMessage(t('errorMessages.userUnauthorized'));
            } else {
                document.getElementById('alert-login')!.hidden = false;
                setType('error');
                setMessage(t('errorMessages.middlewareNotAvailableText'));
            }
        } else {
            // login.roles = [0, 6];
            setUser(login);
        }
        setLoading(false);
        //        if(!login.contain("error")) this.props.setLogin(JSON.stringify(login));
        //this.props.setLogin(values.username);
    };

    return (
        <Content className="site-layout-background">
            <Form
                id="customer-form"
                className="center"
                onFinish={onFinish}
                //onFinishFailed={this.onFinishFailed}
            >
                <h2 className="font-weight-600 title-content">
                    {t('title')} - {t('subtitle')}
                </h2>
                <h3 style={{ paddingBottom: 15 }}>{t('commonClass.signIn')}</h3>
                <div hidden id="alert-login" className="center">
                    <Alert message={message} type={type} showIcon />
                </div>
                <Form.Item
                    {...formItemLayout}
                    className="center"
                    name="username"
                    label={t('commonClass.username')}
                    rules={[
                        {
                            required: true,
                            message: t('errorMessages.usernameReq'),
                        },
                    ]}
                >
                    <Input placeholder={t('commonClass.username')} />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="password"
                    label={t('commonClass.password')}
                    rules={[
                        {
                            required: true,
                            message: t('errorMessages.passwordReq'),
                        },
                    ]}
                >
                    <Input.Password placeholder={t('commonClass.password')} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" id="login-submit">
                        {t('commonClass.login')}
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    );
};

export default LoginForm;
