import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/modules/agencies-flow-dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Calendar, Col, Form, Row } from 'antd';
import moment from 'moment';
import { useStore } from 'store';
import FlowServices from 'js/data/flow-services';
import { AgenciesFlowAnalytics } from 'js/constants/types/flow';
import { Company } from 'js/constants/types/company';
import i18n from 'js/tools/i18n';
import { DateFormats, StringFormats } from 'js/config/config';
import { Link } from 'react-router-dom';

const { shortDateFormatUS } = StringFormats;

const AgenciesFlowDashBoard = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const currentLanguage = i18n.language;
    const today = moment();
    const oneYearAgo = moment().subtract(1, 'years');
    const setLoading = useStore((state) => state.setLoading);
    const [analitycs, setAnalytics] = useState<AgenciesFlowAnalytics[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [days, setDays] = useState<moment.Moment[]>([moment().subtract(2, 'days'), moment().subtract(1, 'days'), moment()]);

    const onSubmit = (value: { day: moment.Moment }) => {
        setDays([moment(value.day).subtract(2, 'days'), moment(value.day).subtract(1, 'days'), moment(value.day)]);
        getFlowsAnalytics(value.day.format(shortDateFormatUS));
    };

    const getFlowsAnalytics = async (date?: string) => {
        const bodyRequest = { startDate: date || null, period: 365 };

        setLoading(true);
        const flowAnalytics = await FlowServices.agenciesFlowAnalytics(bodyRequest);
        if (flowAnalytics instanceof Response) {
            setAnalytics([]);
        } else {
            setAnalytics(flowAnalytics);
        }
        setLoading(false);
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    // const renderType = (type: OutputFlowType, companyId: number) => {
    //     return (
    //         <div key={type.fileType}>
    //             <p className={styles['day-file-type']} key={type.fileType}>
    //                 {type.fileType}
    //             </p>
    //             {type.analytics.map((file) => {
    //                 return <FlowPopover file={file} type={type} companyId={companyId} key={file.fileName} />;
    //             })}
    //         </div>
    //     );
    // };

    useEffect(() => {
        getCompanies();
        getFlowsAnalytics();
    }, []);

    return (
        <>
            <h2 className={styles.title}>{t('agenciesFlows.Dashboard')}</h2>
            <p>{t('agenciesFlows.DashboardDescription')}</p>
            <Row gutter={20}>
                <Col xs={24} sm={24} lg={24} xl={17}>
                    {companies.map((company) => {
                        const item = analitycs.find((x) => x.companyId === company.companyId);

                        return (
                            <section key={company.companyId} className={styles['company-wrapper']}>
                                <h4 className={styles.company}>{company.companyName}</h4>
                                <Row gutter={[30, 30]}>
                                    {days.map((day, index) => {
                                        const dayAnalytics = item?.analytics.find((x) => moment(x.fileDate).isSame(day, 'days'));
                                        const dayString = day.locale(currentLanguage.split('-')[0]).format('dddd');
                                        const selectedDay = index === days.length - 1;

                                        if (dayAnalytics) {
                                            return (
                                                <Col xs={24} sm={8} lg={8} xl={8} className={styles['day-col']} key={dayAnalytics.fileDate}>
                                                    <div className={styles['day-box']}>
                                                        <h4 className={`${styles['day-title']} ${selectedDay ? 'selected' : ''}`}>
                                                            <span>{dayString}</span>
                                                            <span>{day.format(DateFormats[currentLanguage])}</span>
                                                        </h4>
                                                        <Row>
                                                            <Col xs={24} sm={24} lg={24} xl={24}>
                                                                <div className={styles['day-item']}>
                                                                    {/* <span className={styles['day-item-label']}>{t('agenciesFlows.FileName')}</span> */}
                                                                    <p className={styles['day-item-label-sm']}>
                                                                        {'RM_VIASAT_AGENCIES_REQUEST_2022-07-2'}1
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div className={styles['day-item']}>
                                                            <span className={styles['day-item-label']}>
                                                                {t('agenciesFlows.fileNumRows')}
                                                            </span>
                                                            <strong>{dayAnalytics.fileNumRows}</strong>
                                                        </div>
                                                        <div className={styles['day-item']}>
                                                            <span className={styles['day-item-label']}>
                                                                {t('agenciesFlows.importedRows')}
                                                            </span>
                                                            <strong>{dayAnalytics.rowsInserted}</strong>
                                                        </div>
                                                        <div className={styles['day-item']}>
                                                            <span className={styles['day-item-label']}>
                                                                {t('agenciesFlows.updatedRows')}
                                                            </span>
                                                            <strong>{dayAnalytics.rowsUpdated}</strong>
                                                        </div>
                                                        <div
                                                            className={`${styles['day-item']} ${
                                                                dayAnalytics.result == 'ERROR' ? styles.error : styles.success
                                                            }`}
                                                        >
                                                            <span className={styles['day-item-label']}>{t('agenciesFlows.result')}</span>
                                                            {dayAnalytics.result == 'ERROR' ? (
                                                                <strong>{t('agenciesFlows.resultErrorMsg').toUpperCase()}</strong>
                                                            ) : (
                                                                <strong>{t('agenciesFlows.resultOkMsg').toUpperCase()}</strong>
                                                            )}
                                                        </div>

                                                        {/* <span className={styles['day-item-label']}>{t('flows.importedRows')}</span>
                                                        <strong>{dayAnalytics.rowinserted}</strong>

                                                        <span className={styles['day-item-label']}>{t('flows.importErrors')}</span>
                                                        <strong>{dayAnalytics.rowupdated}</strong> */}
                                                    </div>
                                                </Col>
                                            );
                                        } else {
                                            return (
                                                <Col xs={24} sm={8} lg={8} xl={8} className={styles['day-col']} key={day.toLocaleString()}>
                                                    <div className={styles['day-box']}>
                                                        <h4 className={`${styles['day-title']} ${selectedDay ? styles['selected'] : ''}`}>
                                                            <span>{dayString}</span>
                                                            <span>{day.format(DateFormats[currentLanguage])}</span>
                                                        </h4>
                                                        <div className={styles['day-item-nodata']}>
                                                            <span>{t('agenciesFlows.noFilesImported')}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        }
                                    })}
                                </Row>
                            </section>
                        );
                    })}
                </Col>
                <Col xs={24} sm={24} lg={24} xl={7}>
                    <aside className={styles['sidebar-wrapper']}>
                        <h4>{t('flows.selectDate')}</h4>
                        <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ ['day']: moment() }}>
                            <Form.Item name="day">
                                <Calendar validRange={[oneYearAgo, today]} fullscreen={false} />
                            </Form.Item>
                            <Form.Item>
                                <Button className={styles.submit} type="primary" htmlType="submit">
                                    {t('flows.search')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </aside>
                </Col>
            </Row>
        </>
    );
};

export default AgenciesFlowDashBoard;
