import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, Modal, Row, Spin } from 'antd';
import logoViasatGroup from 'assets/img/logo-viasat-group.png';
import Homepage from './js/pages/homepage';
import LoginForm from './js/components/forms/login-form';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ErrorPage from './js/pages/error-page';
import i18n from './js/tools/i18n';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SePAddContract from './js/pages/sicurieprotetti/sep-add-contract';
import MainMenu from 'js/components/main-menu';
import BreadCrumb from 'js/components/breadcrumb';
import SePViewContracts from './js/pages/sicurieprotetti/sep-view-contracts';
import BatchesManager from './js/pages/batches-manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faHome,
    faLocationArrow,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faFileAlt,
    faFileInvoiceDollar,
    faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import Icon from '@ant-design/icons';
import logoSicuriProtetti from 'assets/img/logo-sicurieprotetti.png';
import { MenuItem } from 'js/constants/types/menuItem';
import AuthRouter from 'js/components/authRouter';
import { useStore } from 'store';
import MessagesDashboard from 'js/pages/device-messages/messages-dashboard';
import MessagesLatest from 'js/pages/device-messages/messages-latest';
import { MenuOutlined } from '@ant-design/icons';
import InputFlowDashboard from 'js/pages/input-flows/input-flow-dashboard';
import InputFlowManagement from 'js/pages/input-flows/input-flow-management';
import OutputFlowDashBoard from 'js/pages/output-flows/output-flow-dashboard';
import OutputFlowManagement from 'js/pages/output-flows/output-flow-management';
import PoliciesManagement from 'js/pages/policies-management';
import Invoices from 'js/pages/invoices/invoices';
import AgenciesFlowManagement from 'js/pages/agencies-flows/agencies-flow-management';
import AgenciesFlowDashBoard from 'js/pages/agencies-flows/agencies-flow-dashboard';
import ReportsManagement from 'js/pages/reports/insurance-reports';

const { confirm } = Modal;
const { Header } = Layout;

const App = () => {
    const { t } = useTranslation();
    const user = useStore((state) => state.user);
    const setUser = useStore((state) => state.setUser);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const loading = useStore((state) => state.loading);
    const setLoading = useStore((state) => state.setLoading);

    // console.log('API :' + process.env.REACT_APP_API_ENDPOINT);
    // console.log('OPENSTREETMAP :' + process.env.REACT_APP_OPENSTREETMAP);
    // console.log('VIASTASSO :' + process.env.REACT_APP_VIASATSSO);

    useEffect(() => {
        if (document.getElementById(i18n.language) != null) document.getElementById(i18n.language)!.classList.add('language-active');
        // stop loading on error
        setLoading(false);
    }, []);

    function changeLanguage(i18n: any, lang: string) {
        if (i18n.language !== lang) {
            confirm({
                title: t('commonClass.changeLanguage'),
                content: t('commonClass.proceed?'),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    if (document.getElementById(i18n.language) != null)
                        document.getElementById(i18n.language)!.classList.remove('language-active');
                    if (document.getElementById(lang) != null) document.getElementById(lang)!.classList.add('language-active');
                    i18n.changeLanguage(lang);
                },
            });
        }
    }

    function checkUser() {
        return !!user?.uid;
    }

    const MenuList: MenuItem[] = [
        {
            id: 1,
            label: t('commonClass.homepage'),
            url: '/',
            image: <FontAwesomeIcon icon={faHome} className="menu-icon anticon" color={'#1890ff'} />,
            component: <Homepage />,
            exactPath: true,
            auth: true,
            requiredRoles: [],
            subMenu: [],
        },
        // {
        //     id: 2,
        //     label: t('sicuri&protetti.name'),
        //     url: undefined,
        //     image: <Icon component={() => <img alt="" src={logoSicuriProtetti} className="menu-icon" />} />,
        //     component: null,
        //     exactPath: false,
        //     auth: true,
        //     requiredRoles: [],
        //     subMenu: [
        //         {
        //             id: 3,
        //             label: t('commonClass.newContract'),
        //             url: '/sep/add',
        //             image: null,
        //             component: <SePAddContract />,
        //             exactPath: false,
        //             auth: true,
        //             requiredRoles: [],
        //             subMenu: [],
        //         },
        //         {
        //             id: 4,
        //             label: t('commonClass.viewContracts'),
        //             url: '/sep/view',
        //             image: null,
        //             component: <SePViewContracts />,
        //             exactPath: false,
        //             auth: true,
        //             requiredRoles: [],
        //             subMenu: [],
        //         },
        //     ],
        // },
        {
            id: 5,
            label: t('commonClass.batches'),
            url: '/batches-manager',
            image: <FontAwesomeIcon icon={faCog} className="menu-icon anticon" color={'#1890ff'} />,
            component: <BatchesManager />,
            exactPath: false,
            auth: true,
            requiredRoles: [
                /* UserRoles.IntReports */
            ],
            subMenu: [],
        },
        {
            id: 6,
            label: t('commonClass.messages'),
            url: undefined,
            image: <FontAwesomeIcon icon={faLocationArrow} className="menu-icon anticon" color={'#1890ff'} />,
            component: null,
            exactPath: false,
            auth: true,
            requiredRoles: [],
            subMenu: [
                {
                    id: 7,
                    label: t('commonClass.messagesDashboard'),
                    url: '/messages-dashboard',
                    image: null,
                    component: <MessagesDashboard />,
                    exactPath: false,
                    auth: true,
                    requiredRoles: [],
                    subMenu: [],
                },
                {
                    id: 8,
                    label: t('commonClass.latestMessages'),
                    url: '/latest-messages',
                    image: null,
                    component: <MessagesLatest />,
                    exactPath: false,
                    auth: true,
                    requiredRoles: [],
                    subMenu: [],
                },
            ],
        },
        {
            id: 9,
            label: t('commonClass.flows'),
            url: undefined,
            image: <FontAwesomeIcon icon={faCloudDownloadAlt} className="menu-icon anticon" color={'#1890ff'} />,
            component: null,
            exactPath: false,
            auth: true,
            requiredRoles: [],
            subMenu: [
                {
                    id: 10,
                    label: t('commonClass.inputFlows'),
                    url: undefined,
                    image: null,
                    component: null,
                    exactPath: false,
                    auth: true,
                    requiredRoles: [],
                    subMenu: [
                        {
                            id: 11,
                            label: t('commonClass.inputFlowDashboard'),
                            url: '/input-flow-dashboard',
                            image: null,
                            component: <InputFlowDashboard />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                        {
                            id: 12,
                            label: t('commonClass.inputFlowManagement'),
                            url: '/input-flow-management',
                            image: null,
                            component: <InputFlowManagement />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                    ],
                },
                {
                    id: 13,
                    label: t('commonClass.outputFlows'),
                    url: undefined,
                    image: <FontAwesomeIcon icon={faCloudUploadAlt} className="menu-icon anticon" color={'#1890ff'} />,
                    component: null,
                    exactPath: false,
                    auth: true,
                    requiredRoles: [],
                    subMenu: [
                        {
                            id: 14,
                            label: t('commonClass.outputFlowDashboard'),
                            url: '/output-flow-dashboard',
                            image: null,
                            component: <OutputFlowDashBoard />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                        {
                            id: 15,
                            label: t('commonClass.outputFlowManagement'),
                            url: '/output-flow-management',
                            image: null,
                            component: <OutputFlowManagement />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                    ],
                },
                {
                    id: 16,
                    label: t('commonClass.agenciesFlows'),
                    url: undefined,
                    image: <FontAwesomeIcon icon={faCloudUploadAlt} className="menu-icon anticon" color={'#1890ff'} />,
                    component: null,
                    exactPath: false,
                    auth: true,
                    requiredRoles: [],
                    subMenu: [
                        {
                            id: 17,
                            label: t('commonClass.agenciesFlowDashboard'),
                            url: '/agencies-flow-dashboard',
                            image: null,
                            component: <AgenciesFlowDashBoard />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                        {
                            id: 18,
                            label: t('commonClass.agenciesFlowManagement'),
                            url: '/agencies-flow-management',
                            image: null,
                            component: <AgenciesFlowManagement />,
                            exactPath: false,
                            auth: true,
                            requiredRoles: [],
                            subMenu: [],
                        },
                    ],
                },
            ],
        },
        {
            id: 19,
            label: t('commonClass.policiesManagement'),
            url: '/policies-management',
            image: <FontAwesomeIcon icon={faFileAlt} className="menu-icon anticon" color={'#1890ff'} />,
            component: <PoliciesManagement />,
            exactPath: false,
            auth: true,
            requiredRoles: [
                /* UserRoles.IntReports */
            ],
            subMenu: [],
        },
        {
            id: 20,
            label: t('commonClass.invoices'),
            url: '/invoices',
            image: <FontAwesomeIcon icon={faFileInvoiceDollar} className="menu-icon anticon" color={'#1890ff'} />,
            component: <Invoices />,
            exactPath: false,
            auth: true,
            requiredRoles: [
                /* UserRoles.IntReports */
            ],
            subMenu: [],
        },
        // area report
        {
            id: 21,
            label: t('commonClass.insuranceReports'),
            url: '/insurance-reports',
            image: <FontAwesomeIcon icon={faChartLine} className="menu-icon anticon" color={'#1890ff'} />,
            component: <ReportsManagement />,
            exactPath: false,
            auth: true,
            requiredRoles: [],
            subMenu: [
                // {
                //     id: 22,
                //     label: t('commonClass.insuranceReports'),
                //     url: '/insurance-reports',
                //     image: null,
                //     component: <ReportsManagement />,
                //     exactPath: false,
                //     auth: true,
                //     requiredRoles: [],
                //     subMenu: []
                // },
                // {
                //     id: 23,
                //     // label: t('commonClass.latestMessages'),
                //     label: 'Other reports',
                //     url: '',
                //     image: null,
                //     // component: <MessagesLatest />,
                //     component: null,
                //     exactPath: false,
                //     auth: true,
                //     requiredRoles: [],
                //     subMenu: []
                // }
            ]
        }
    ];

    const logout = async () => {
        setUser({});
        sessionStorage.removeItem('viasatip');
    };

    const renderRouter = (item: MenuItem) => {
        const hasSubMenu = item.subMenu.length;

        if (hasSubMenu) {
            return item.subMenu.map((subItem: MenuItem) => {
                return renderRouter(subItem);
            });
        } else {
            return (
                <Route
                    exact={item.exactPath}
                    key={item.id}
                    path={item.url}
                    requiredRoles={item.requiredRoles}
                    render={() =>
                        item.auth && item.component ? (
                            <AuthRouter requiredRoles={item.requiredRoles}>{item.component}</AuthRouter>
                        ) : (
                            item.component
                        )
                    }
                />
            );
        }
    };

    return (
        <Layout>
            <Header className="header">
                <Row justify="space-between">
                    {checkUser() && (
                        <Col xs={4} sm={0}>
                            <Button type="primary" icon={<MenuOutlined />} onClick={() => setMenuOpen(!menuOpen)} />
                        </Col>
                    )}
                    <Col xs={12} sm={4} md={4} lg={2} xl={2}>
                        <a href={'/'}>
                            <img src={logoViasatGroup} width={100} alt={'Viasat'} />
                        </a>
                    </Col>
                    <Col xs={0} sm={12} md={16} lg={18} xl={20}></Col>
                    <Col xs={8} sm={8} md={4} lg={4} xl={2} className="gutter-row language-group right">
                        <button className="language-button italian" id="it-IT" onClick={() => changeLanguage(i18n, 'it-IT')}></button>
                        <button className="language-button english" id="en-US" onClick={() => changeLanguage(i18n, 'en-US')}></button>
                    </Col>
                </Row>
            </Header>
            <Layout className="main-container">
                <Router>
                    {checkUser() && <MainMenu menuList={MenuList} isOpen={menuOpen} logout={logout} />}
                    <Layout className={checkUser() ? 'main-content' : 'login-form'}>
                        {checkUser() && (
                            <>
                                <BreadCrumb menuList={MenuList} />
                            </>
                        )}
                        <Spin size="large" spinning={loading}>
                            <Switch>
                                {/* Login page */}
                                <Route path="/login" render={() => (!checkUser() ? <LoginForm /> : <Redirect to="/" />)} />

                                {/* Menu */}
                                {MenuList.map((item) => renderRouter(item))}

                                {/* Error pages */}
                                <Route path="/error/:title&:message&:status" component={(p) => <ErrorPage {...p} />} />
                                <Route component={(p) => <ErrorPage {...p} />} />
                            </Switch>
                        </Spin>
                    </Layout>
                </Router>
            </Layout>
        </Layout>
    );
};

export default App;
