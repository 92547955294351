/* eslint-disable quotes */
/*
    This code brings the companies state code logic contained
    inside the table CODICI_STATO_COMPAGNIE in the front-end.
*/

export const POLICY_STATE_CODES = {
    // CATTOLICA
    '30100': {
        '0': {
            color: 'red',
            label: 'Emissione polizza (prima emissione o sostituzione) con inserimento a nuovo di terminale Viasat',
        },
        '1': {
            color: 'diamond',
            label: 'Sostituzione polizza con cambio veicolo e mantenimento del terminale Viasat',
        },
        '2': {
            color: 'yellow',
            label: 'Sostituzione polizza con cambio veicolo e disinstallazione del terminale Viasat',
        },
        '3': {
            color: 'green',
            label: 'Sostituzione polizza senza cambio veicolo e mantenimento del terminale Viasat',
        },
        '4': {
            color: 'yellow',
            label: 'Sostituzione polizza senza cambio veicolo e disinstallazione del terminale Viasat',
        },
        '5': {
            color: 'blue',
            label: 'Sospensione Polizza',
        },
        '6': {
            color: 'green',
            label: 'Riattivazione polizza con mantenimento del terminale Viasat',
        },
        '7': {
            color: 'yellow',
            label: 'Riattivazione polizza senza mantenimento del terminale Viasat',
        },
        '8': {
            color: 'green',
            label: 'Cessione polizza',
        },
        '9': {
            color: 'green',
            label: 'Riammissione in vigore',
        },
        '10': {
            color: 'yellow',
            label: "Annullo polizza (storno dall'origine)",
        },
        '11': {
            color: 'yellow',
            label: "Storno definitivo polizza (diverso da sostituzione e diverso da storno dall'origine)",
        },
        '12': {
            color: 'green',
            label: 'Annulla ultima modifica e ripristina situazione precedente.',
        },
        '13': {
            color: 'diamond',
            label: 'Variazione manuale della polizza',
        },
        '14': {
            color: 'green',
            label: 'Rinnovo',
        },
        '17': {
            color: 'green',
            label: 'Emissione assistita da ex IA a GI (al rinnovo)',
        },
        '18': {
            color: 'green',
            label: 'Emissione assistita da ex TORO a GI (al rinnovo)',
        },
        '19': {
            color: 'green',
            label: 'Cambio Agenzia',
        },
        '20': {
            color: 'green',
            label: 'Emissione assistita da ex LLOYD a GI (al rinnovo)',
        },
        '21': {
            color: 'diamond',
            label: 'Riattivazione polizza con mantenimento del terminale e variazione veicolo',
        },
        '31': {
            color: 'diamond',
            label: 'Sostituzione polizza con cambio veicolo, mantenimento sat. ma cambio dispositivo',
        },
        '33': {
            color: 'green',
            label: 'Sostituzione polizza senza cambio veicolo, mantenimento sat. ma cambio dispositivo',
        },
        '41': {
            color: 'diamond',
            label: 'Riattivazione polizza con cambio veicolo, mantenimento sat. ma cambio dispositivo',
        },
        '43': {
            color: 'green',
            label: 'Riattivazione polizza senza cambio veicolo, mantenimento sat. ma cambio dispositivo',
        },
        '47': {
            color: 'red',
            label: 'Richiesta Installazione da Direzione',
        },
    },
};
