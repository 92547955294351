import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/modules/invoices.module.scss';
import { Button, Space } from 'antd';
import { Bill } from 'js/constants/types/bill';
import { useTranslation } from 'react-i18next';
import { OutputFile } from 'js/constants/types/outputFile';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import { StatusInvoice } from 'js/constants/enum/statusInvoice';
//import { Number } from 'react-intl-number-format/dist/index.js';

interface IProps {
    bill: Bill;
    file: OutputFile;
    processInvoice: () => void;
    onRefreshStatusInvoice: () => Promise<void>;
}

const InvoicesDetails: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const [clicked, setClicked] = useState<boolean>(false);
    const currentLanguage = i18n.language;

    const getStatusLabel = (code: number): string => {
        switch (code) {
            case StatusInvoice.ContainsErrors:
                return t('invoices.containsErrors');
            case StatusInvoice.ToProcess:
                return t('invoices.toProcess');
            case StatusInvoice.ToSend:
                return t('invoices.toSend');
            case StatusInvoice.InProcess:
                return t('invoices.inProcess');
            case StatusInvoice.Sent:
                return t('invoices.sent');
            default:
                return '';
        }
    };

    const getStatusStyleBlink = (code: number): string => {
        switch (code) {
            case StatusInvoice.ContainsErrors:
                return 'blinkcontainsErrors';
            case StatusInvoice.ToProcess:
                return 'blinktoProcess';
            case StatusInvoice.ToSend:
                return 'blinktoSend';
            case StatusInvoice.InProcess:
                return 'blinkinProcess';
            case StatusInvoice.Sent:
                return 'blinksent';
            default:
                return '';
        }
    };

    const onClick = () => {
        setClicked(true);
        props.processInvoice();
    };

    useEffect(() => {
        const intervalUpdating = setInterval(() => props.onRefreshStatusInvoice(), 10000);
        return () => clearInterval(intervalUpdating);
    });

    return (
        <div className={styles.actions}>
            <div className={styles.actionsRow}>
                <div className={styles.actionsCol}>
                    <p className={styles.actionsLabel}>
                        <b>{t('invoices.providerId')}:</b> {props.bill?.providerId}
                    </p>
                    <p className={styles.actionsLabel}>
                        <b>{t('invoices.providerFiscalId')}:</b> {props.bill?.providerFiscalId}
                    </p>
                </div>
                <div className={styles.actionsCol}>
                    <p className={styles.actionsLabel}>
                        <b>{t('invoices.invoiceNumber')}:</b> {props.bill?.invoiceNumber}
                    </p>
                    <p className={styles.actionsLabel}>
                        <b>{t('invoices.invoiceDate')}:</b>{' '}
                        {moment(props.bill?.invoiceDate, 'DD/MM/YYYY').format(DateFormats[currentLanguage])}
                    </p>
                </div>
                <div className={styles.actionsCol}>
                    <Space direction="horizontal" size={2}>
                        {/* <b>{t('invoices.totalAmount')}:</b> <Number>{props.file.totalAmount}</Number> */}
                    </Space>
                    <div className={styles.actionsLabel}>
                        <b>{t('invoices.status')}:</b>
                        <div className={styles[getStatusStyleBlink(props.file.status)]}>{getStatusLabel(props.file.status)}</div>
                    </div>
                </div>
                <div className={styles.actionsCol}>
                    {props.file.fileName ? (
                        <p className={styles.actionsLabel}>
                            <b>{t('invoices.filename')}:</b> {props.file.fileName}
                        </p>
                    ) : (
                        ''
                    )}
                    {props.file.sendDate ? (
                        <p className={styles.actionsLabel}>
                            <b>{t('invoices.sendDate')}:</b> {moment(new Date(props.file.sendDate)).format(DateFormats[currentLanguage])}
                        </p>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div>
                <Button disabled={props.file.status !== -2 || clicked} onClick={onClick}>
                    {t('invoices.processInvoice')}
                </Button>
            </div>
        </div>
    );
};

export default InvoicesDetails;
