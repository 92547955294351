import { Button, Space, Table, Tooltip } from 'antd';
import { POLICY_STATE_CODES } from 'js/constants/resources/policy-state-codes';
import styles from 'assets/styles/modules/policies-management.module.scss';
import { Policy } from 'js/constants/types/policy';
import Dot from 'js/components/dot';
import React from 'react';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';

interface IProps {
    policies?: Policy[];
    company?: number;
    onEditActivationDate: any;
    setModalVisible: (value: React.SetStateAction<boolean>) => void;
}

const PoliciesManagementTable = (props: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const currentLanguage = i18n.language;

    /*
        Dealer station logic contained inside the VISTA_CATTOLICA_GESTORE_EXT1 database view.
    */
    const mapCentralState = (centralState: number) => {
        const companyCodes = POLICY_STATE_CODES[`${props.company}`];

        if (companyCodes) {
            const color = companyCodes[`${centralState}`]?.color;
            const label = companyCodes[`${centralState}`]?.label;

            return <Dot color={color} tooltipText={label} />;
        } else {
            return '-';
        }
    };

    /*
        Dealer station logic contained inside the VISTA_CATTOLICA_GESTORE_EXT1 database view.
    */
    const mapServiceState = (serviceState: number) => {
        switch (serviceState) {
            case 0:
                return <Dot color="green" />;
            case -1:
                return <Dot color="red" />;
            case -2:
                return <Dot color="red" />;
            case -3:
                return <Dot color="red" />;
            default:
                return <Dot color="yellow" />;
        }
    };

    /*
        Dealer station logic contained inside the VISTA_CATTOLICA_GESTORE_EXT1 database view.
    */
    const mapSimState = (simState: number) => {
        switch (simState) {
            case 0:
                return <Dot color="green" />;
            case 1:
                return <Dot color="green" />;
            case -5:
                return <Dot color="red" />;
            case -4:
                return <Dot color="red" />;
            case 2:
                return <Dot color="red" />;
            case 3:
                return <Dot color="red" />;
            case 9:
                return <Dot color="red" />;
            case 10:
                return <Dot color="red" />;
            case 6:
                return <Dot color="yellow" />;
            case 7:
                return <Dot color="yellow" />;
            case 8:
                return <Dot color="yellow" />;
            case 4:
                return <Dot color="yellow" />;
            case -2:
                return <Dot color="brown" />;
            case -3:
                return <Dot color="brown" />;
            case -1:
                return <Dot color="brown" />;
            case 5:
                return <Dot color="blue" />;
            case 11:
                return <Dot color="orange" />;
            default:
                return 'warn';
        }
    };

    const columns = [
        {
            title: t('policiesManagement.status'),
            dataIndex: 'contractState',
            key: 1,
            align: 'center' as const,
            render: (state: number) => mapCentralState(state),
        },
        {
            title: t('policiesManagement.policyNumber'),
            dataIndex: 'policyNumber',
            key: 2,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.voucher'),
            dataIndex: 'contractId',
            key: 3,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.insertDate'),
            dataIndex: 'insertDate',
            key: 4,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.expiredInst'),
            dataIndex: 'installationExpirationDate',
            key: 5,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.customer'),
            dataIndex: 'customerName',
            key: 6,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.licensePlate'),
            dataIndex: 'licensePlate',
            key: 7,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.completionDate'),
            dataIndex: 'completionDate',
            key: 8,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.senddateKit'),
            dataIndex: 'sendDate',
            key: 9,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.linitDateActivation'),
            dataIndex: 'limitActivationDate',
            key: 10,
            align: 'center' as const,
            render: (date: Date) => (date ? moment(new Date(date)).format(DateFormats[currentLanguage]) : null),
        },
        {
            title: t('policiesManagement.installationDate'),
            dataIndex: 'installationDate',
            key: 11,
            align: 'center' as const,
        },
        {
            title: t('policiesManagement.statusCentral'),
            dataIndex: 'viasatId',
            key: 12,
            align: 'center' as const,
            render: (viasatId: string) => (viasatId ? <Dot color="green" /> : <Dot color="red" />),
        },
        {
            title: t('policiesManagement.serviceState'),
            dataIndex: 'serviceState',
            key: 13,
            align: 'center' as const,
            render: (serviceState: number) => mapServiceState(serviceState),
        },
        {
            title: t('policiesManagement.simState'),
            dataIndex: 'simState',
            key: 14,
            align: 'center' as const,
            render: (simState: number) => mapSimState(simState),
        },
        {
            title: t('policiesManagement.causeDelay'),
            dataIndex: 'causeDelay',
            key: 15,
            align: 'center' as const,
        },
        {
            key: 16,

            render: (record: Policy) => {
                return (
                    <Space size="middle">
                        <Tooltip title={t('policiesManagement.editLimitDateActTooltip')}>
                            <Button
                                onClick={(event) => props.onEditActivationDate(event, record)}
                                type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    </Space>
                );
            },
        },
    ];

    return (
        <section className={styles.table}>
            <Table dataSource={props.policies} rowKey="contractId" columns={columns} size="small"></Table>
        </section>
    );
};

export default PoliciesManagementTable;
