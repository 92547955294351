import { FileType } from 'js/constants/enum/fileType';
import { Bill, BillProcessed } from 'js/constants/types/bill';
import { OutputFile } from 'js/constants/types/outputFile';
import { BillingsFlowsSearch } from 'js/constants/types/search';
import UtilsServices from 'js/tools/utils-services';
import { useStore } from 'store';

/*
 *  Shared variables and methods
 */
const token = useStore.getState().user?.token;
const headers = new Headers({ Authorization: `Bearer ${token}` });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

useStore.subscribe((state) => {
    headers.set('Authorization', `Bearer ${state.user?.token}`);
});

/*
 *  Services
 */
export default class BillingServices {
    public static getBillingFiles = async (companyId: number): Promise<OutputFile[]> => {
        const response = await fetch(`${baseUrl}/API/Billings/GetBillingFiles?companyId=${companyId}`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getBillingFile = async (search : BillingsFlowsSearch ): Promise<OutputFile> => {
        const response = await fetch(`${baseUrl}/API/Billings/getBillingFile?companyId=${search.companyId}&invoiceNumber=${search.invoiceNumber}`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getBillingDetails = async (companyId: number, invoiceNumber: string): Promise<Bill[]> => {
        const response = await fetch(`${baseUrl}/API/Billings/GetBillingDetails?companyId=${companyId}&invoiceNumber=${invoiceNumber}`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static uploadFile = async (companyId: number, formData: FormData): Promise<void | Response> => {
        const response = await fetch(`${baseUrl}/API/Billings/UploadBillingFile?companyId=${companyId}`, {
            method: 'POST',
            body: formData,
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static processInvoice = async (companyId: number, invoiceNumber: string): Promise<BillProcessed> => {
        const response = await fetch(`${baseUrl}/API/Billings/StartProcessInvoices?companyId=${companyId}&invoiceNumber=${invoiceNumber}`, {
            headers,
            method: 'POST',
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static  getBillingsFlowsFile =async (filename: string, fileType: FileType, search: BillingsFlowsSearch) => {
        const response = await fetch(
            `${baseUrl}/API/Billings/GetFile?filename=${filename}&filetype=${fileType}&companyId=${search.companyId}&InvoiceNumber=${search.invoiceNumber}`,
            { headers }
        );
       
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const temporaryLink = document.createElement('a');

        temporaryLink.href = url;
        switch (fileType){
            case FileType.Csv:
                filename = filename + '.csv';
                break;
            case FileType.Excel:
                filename = filename + '.xlsx';
                break;
        }
       
        temporaryLink.download = filename;
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        temporaryLink.remove();        
      }


}
