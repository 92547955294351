import { message } from 'antd';
import { useStore } from 'store';

export default class UtilsServices {
    public static async processResponse(response: Response) {
        if (!response.ok) {
            useStore.setState({ loading: false });
            if (response.status === 401) {
                const error = {
                    title: 'errorMessages.sessionExpired',
                    message: 'errorMessages.sessionExpiredText',
                    status: response.status,
                };
                window.location.href = '/error/' + error.title + '&' + error.message + '&' + error.status;
            } else {
                this.showErrorMessage(response);
            }

            return await response;
        }

        try {
            const data = await response.json();
            return data;
        } catch {
            return undefined;
        }
    }

    public static async showErrorMessage(response: Response) {
        const responseBody = await response.json();

        message.error({
            content: `Error ${responseBody.httpCode}: ${responseBody.message}`,
            duration: 5,
            style: {
                marginTop: '80px',
            },
        });
    }

    public static async showSuccessMessage(response: Response) {
        const responseBody = await response.json();

        message.success({
            content: `Error ${response.status}: ${responseBody.error}`,
            duration: 5,
            style: {
                marginTop: '80px',
            },
        });
    }
}
