import { Button, message, Popover } from 'antd';
import { OutputFlowFile, OutputFlowType } from 'js/constants/types/flow';
import FlowServices from 'js/data/flow-services';
import React, { useState } from 'react';
import { useStore } from 'store';
import styles from 'assets/styles/modules/output-flow-dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { DateFormats, DateLongFormats } from 'js/config/config';

interface IProps {
    file: OutputFlowFile;
    type: OutputFlowType;
    companyId: number;
}

const FlowPopover = ({ file, type, companyId }: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const [visible, setVisible] = useState<boolean>(false);
    const currentLanguage = i18n.language;
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const downloadFile = async () => {
        setLoading(true);
        await FlowServices.downloadFile(`${file.fileName}.csv`, type.fileType, companyId);
        setLoading(false);
    };

    const resendFile = async () => {
        setLoading(true);
        const response = await FlowServices.resendFile(file.fileDate, companyId, type.fileType);

        if (!(response instanceof Response)) {
            setVisible(false);
            message.success(t('flows.resendSuccess'));
        }
        setLoading(false);
    };

    const content = () => {
        return (
            <>
                <Button block className={styles.download} onClick={downloadFile}>
                    {t('flows.download')}
                </Button>
                {type.fileType === 'RIS' || type.fileType === 'IMP' ? (
                    <Button block onClick={resendFile}>
                        {t('flows.reupload')}
                    </Button>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <Popover content={content} title={file.fileName} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
            <div className={styles['day-item']}>
                <span className={styles['day-item-label']}>{file.fileName}</span>
                <strong>{file.numRows}</strong>
            </div>
        </Popover>
    );
};

export default FlowPopover;
