import { Button, DatePicker, Form, message, Modal } from 'antd';
import React from 'react';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { DateFormats, StringFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import { Utils } from 'js/tools/utils';
import moment from 'moment';
import PolicyServices from 'js/data/policy-services';
const { shortDateFormatUS } = StringFormats;

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCompany: number | undefined;
    selectedPolicyNumber: string | undefined;
    selectedContractId: string;
    refreshPolicies: () => Promise<void>;
}

const PolicyUpdateActivationDateModal = (props: IProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);
    const currentLanguage = i18n.language;
    const utils = new Utils();

    const initialValues = {
        limitActivationDate: moment().add(30, 'days'),
    };

    const onSubmit = async (value: { limitActivationDate: Date }) => {
        setLoading(true);

        const dateTime1 = moment(value.limitActivationDate).format(shortDateFormatUS);
        const response = await PolicyServices.updateExpirationDate(props.selectedCompany!, props.selectedContractId, dateTime1);

        if (!(response instanceof Response)) {
            message.success(t('policiesManagement.updateSuccess'));
        }

        setLoading(false);
        props.setVisible(false);
        props.refreshPolicies();
    };

    return (
        <Modal
            title={`${t('policiesManagement.policyNumber')} (${props.selectedPolicyNumber})`}
            visible={props.visible}
            onOk={() => false}
            onCancel={() => props.setVisible(false)}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={initialValues}>
                <Form.Item
                    name="limitActivationDate"
                    label={t('policiesManagement.limitActivationDate')}
                    rules={[{ required: true, message: `${t('policiesManagement.requiredLlimitActivationDate')}` }]}
                >
                    <DatePicker
                        className={styles['date-picker']}
                        format={DateFormats[currentLanguage]}
                        allowClear={false}
                        value={moment(initialValues.limitActivationDate).add(60, 'days')}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className={styles['modal-save']}>
                        {t('flows.save')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PolicyUpdateActivationDateModal;
