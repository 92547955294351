import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { Company } from 'js/constants/types/company';
import FlowServices from 'js/data/flow-services';
import { Utils } from 'js/tools/utils';
import { useStore } from 'store';
import { InstallationFlow, MatchResultFlow } from 'js/constants/types/flow';

const { Option } = Select;

interface IProps {
    setInstallations: (value: React.SetStateAction<InstallationFlow[]>) => void;
    setMatchResults: (value: React.SetStateAction<MatchResultFlow[]>) => void;
    form: FormInstance<any>;
}

const OutputFlowFilters = (props: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const [companies, setCompanies] = useState<Company[]>([]);

    const isActiveAtLeastOneAdditionalFilter = () => {
        const formValues = props.form.getFieldsValue();

        if (formValues.licensePlate || formValues.viasatId || formValues.voucherNumber) {
            return true;
        }

        return false;
    };

    const getMatchResults = async (formData: { companyId: number; licensePlate: string; voucherNumber: string; viasatId: string }) => {
        let matchResults: MatchResultFlow[] = [];
        // Prevent API call --> viasatId only for installations
        if (!formData.viasatId) {
            matchResults = await FlowServices.getMatchResults(formData.companyId, formData.licensePlate, formData.voucherNumber);
        }

        return matchResults;
    };

    const onSubmit = async (formData: { companyId: number; licensePlate: string; voucherNumber: string; viasatId: string }) => {
        setLoading(true);

        if (isActiveAtLeastOneAdditionalFilter()) {
            props.setInstallations(
                await FlowServices.getInstallations(formData.companyId, formData.licensePlate, formData.voucherNumber, formData.viasatId)
            );
            props.setMatchResults(await getMatchResults(formData));
        } else {
            Utils.ShowMessage('error', t('flows.selectFilterError'));
        }

        setLoading(false);
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Form form={props.form} onFinish={onSubmit} layout="vertical">
            <Row gutter={20} align="bottom">
                <Col span={5}>
                    <Form.Item name="companyId" label={t('flows.company')} rules={[{ required: true, message: '' }]}>
                        <Select>
                            {companies.map((company) => {
                                return (
                                    <Option key={company.companyId} value={company.companyId}>
                                        {company.companyName}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name="licensePlate" label={t('flows.licensePlate')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name="voucherNumber" label={t('flows.voucherNumber')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name="viasatId" label={t('flows.serialNumber')} normalize={(value) => value.toUpperCase().trim()}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Button className={styles.search} type="primary" htmlType="submit">
                            {t('flows.search')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default React.memo(OutputFlowFilters);
