import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Table } from 'antd';
import styles from 'assets/styles/modules/messages-latest.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IMessage {
    id: number;
    deviceType: string;
    lastMessage: string;
    activeLast60: boolean;
}

interface IFilters {
    'device-id': string;
    'device-name': string;
    'active-number': number;
    'inactive-number': number;
}

const data: IMessage[] = [
    {
        id: 1,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 2,
        deviceType: 'Blue box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 3,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: false,
    },
    {
        id: 4,
        deviceType: 'Blue box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 5,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 6,
        deviceType: 'Pink box',
        lastMessage: '02/14/2022 11:40:00',
        activeLast60: true,
    },
    {
        id: 7,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 12:18:00',
        activeLast60: true,
    },
    {
        id: 8,
        deviceType: 'Pink box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 9,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 10,
        deviceType: 'Black box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
    {
        id: 11,
        deviceType: 'Blue box',
        lastMessage: '02/14/2022 09:40:00',
        activeLast60: true,
    },
];

const MessagesLatest = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [filteredElements, setFilteredElements] = useState<IMessage[]>([]);

    const columns = [
        {
            title: t('latestMessages.id'),
            dataIndex: 'id',
            key: 1,
        },
        {
            title: t('latestMessages.deviceType'),
            dataIndex: 'deviceType',
            key: 2,
        },
        {
            title: t('latestMessages.lastMessage'),
            dataIndex: 'lastMessage',
            key: 3,
            sorter: (a, b) => a.lastMessage - b.lastMessage, // TODO: fix rule
        },
        {
            title: t('latestMessages.activeLastMin'),
            dataIndex: 'activeLast60',
            key: 4,
        },
    ];

    const onSubmit = (filters: IFilters) => {
        const activeLast = moment().subtract(filters['active-number'], 'minutes');
        const inactiveLast = moment().subtract(filters['inactive-number'], 'minutes');

        setFilteredElements(
            messages.filter(
                (message) =>
                    (!filters['device-id'] || message.id.toString().includes(filters['device-id'])) &&
                    (!filters['device-name'] || message.deviceType.toLowerCase().includes(filters['device-name'])) &&
                    (!filters['active-number'] || moment(message.lastMessage).isAfter(activeLast)) &&
                    (!filters['inactive-number'] || moment(message.lastMessage).isBefore(inactiveLast))
            )
        );
    };

    const onReset = () => {
        form.resetFields();
        setFilteredElements(messages);
    };

    useEffect(() => {
        setMessages(data);
        setFilteredElements(data);
    }, [data]);

    return (
        <>
            <Row gutter={25}>
                {/* Table */}
                <Col sm={24} lg={16}>
                    <div className={styles['messages-latest__box']}>
                        <h4 className={styles['messages-latest__title']}>{t('commonClass.latestMessages')}</h4>
                        <Table
                            className={styles['messages-latest__table']}
                            columns={columns}
                            dataSource={filteredElements.map((item, index) => ({
                                ...item,
                                key: index,
                                activeLast60: item.activeLast60 ? 'true' : 'false',
                            }))}
                        ></Table>
                    </div>
                </Col>
                {/* Filters */}
                <Col xs={24} sm={24} lg={8}>
                    <div className={styles['messages-latest__box']}>
                        <h4 className={styles['messages-latest__title']}>{t('latestMessages.filters')}</h4>
                        <Form form={form} layout="vertical" onFinish={onSubmit}>
                            <Form.Item label={t('latestMessages.id')} name="device-id">
                                <Input />
                            </Form.Item>
                            <Form.Item label={t('latestMessages.deviceType')} name="device-name">
                                <Input />
                            </Form.Item>
                            <Form.Item label={t('latestMessages.active')}>
                                <Form.Item name="active-number" noStyle>
                                    <InputNumber min={0} max={1000} />
                                </Form.Item>
                                <span className="ant-form-text"> {t('latestMessages.minutes')}</span>
                            </Form.Item>
                            <Form.Item label={t('latestMessages.inactive')}>
                                <Form.Item name="inactive-number" noStyle>
                                    <InputNumber min={0} max={1000} />
                                </Form.Item>
                                <span className="ant-form-text"> {t('latestMessages.minutes')}</span>
                            </Form.Item>
                            <Button className={styles['messages-latest__button']} type="primary" htmlType="submit">
                                {t('latestMessages.filter')}
                            </Button>
                            <Button className={styles['messages-latest__button']} type="default" htmlType="button" onClick={onReset}>
                                {t('latestMessages.reset')}
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default MessagesLatest;
