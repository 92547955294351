import User from 'js/classes/user';

/*
 *  Shared variables and methods
 */
const headers = new Headers({ 'content-type': 'application/json' });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const processResponse = async (response: Response) => {
    try {
        if (!response.ok) {
            throw await response;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

/*
 *  Services
 */
export default class UserServices {
    public static login = async (loginData: any): Promise<User> => {
        const response = await fetch(`${baseUrl}/API/Users/Login`, { method: 'POST', headers, body: JSON.stringify(loginData) });
        const data = await processResponse(response);

        return data;
    };
}
