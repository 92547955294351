import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuItem } from 'js/constants/types/menuItem';
import { useStore } from 'store';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps {
    menuList: MenuItem[];
    isOpen: boolean;
    logout: () => Promise<void>;
}

const MainMenu = (props: IProps) => {
    const { t } = useTranslation();
    const user = useStore((state) => state.user);

    const setMenuSelectedKey = () => {
        const menus = window.location.pathname.split('/');
        menus.splice(0, 1);
        if (menus.length === 1 && menus[0] === '') menus[0] = '/';
        return menus;
    };

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [selectedKey, setSelectedKey] = useState<string[]>(setMenuSelectedKey());

    const toggleCollapsed = (collapse?: boolean, e?: any) => {
        if (collapse) {
            setCollapsed(true);
            setSelectedKey([e.key]);
        } else {
            setCollapsed(!collapsed);
            setSelectedKey([e.key]);
        }
    };

    const isVisible = (menuItem: MenuItem): boolean => {
        const rolesRestriction = menuItem.requiredRoles.length !== 0;
        const hasRequiredRoles = menuItem.requiredRoles.some((x) => user.roles?.includes(x));

        return !rolesRestriction || hasRequiredRoles;
    };

    return (
        <Sider
            className={`site-layout-background sider-menu ${props.isOpen ? 'menu-open' : ''}`}
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
        >
            <Menu
                onSelect={(e) => toggleCollapsed(true, e)}
                className="left-menu"
                mode="inline"
                defaultSelectedKeys={selectedKey}
                theme="light"
            >
                {props.menuList.map((item) => {
                    const hasSubMenu = item.subMenu.length;

                    if (!isVisible(item)) return;

                    if (hasSubMenu) {
                        return (
                            <SubMenu key={item.id} icon={item.image} title={item.label}>
                                {item.subMenu.map((secondLevel) => {
                                    if (!isVisible(secondLevel)) return;

                                    if (secondLevel.subMenu.length) {
                                        return (
                                            <Menu.ItemGroup key={secondLevel.id} title={secondLevel.label}>
                                                {secondLevel.subMenu.map((thirdLevel) => {
                                                    if (!isVisible(thirdLevel)) return;

                                                    return (
                                                        <Menu.Item key={thirdLevel.id}>
                                                            <Link to={thirdLevel.url}>{thirdLevel.label}</Link>
                                                        </Menu.Item>
                                                    );
                                                })}
                                            </Menu.ItemGroup>
                                        );
                                    } else {
                                        return (
                                            <Menu.Item key={secondLevel.id}>
                                                <Link to={secondLevel.url}>{secondLevel.label}</Link>
                                            </Menu.Item>
                                        );
                                    }
                                })}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.id} icon={item.image}>
                                <Link to={item.url}>{item.label}</Link>
                            </Menu.Item>
                        );
                    }
                })}

                <Menu.Item
                    key="sub2"
                    id="menu-3"
                    className="logout-menu-button"
                    onClick={async () => await props.logout()} 
                    icon={<FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" color={'#1890ff'} />}
                >
                    {t('commonClass.logout')}
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default MainMenu;
