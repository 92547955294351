import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Divider, Form, Row } from 'antd';
import moment from 'moment';
import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import styles from 'assets/styles/modules/messages-dashboard.module.scss';
import MessagesLineChart from 'js/components/messages/messages-line-chart';
import MessagesTable from 'js/components/messages/messages-table';
import MessagesNumbers from 'js/components/messages/messages-numbers';
import MessagesBarChart from 'js/components/messages/messages-bar-chart';
import { Utils } from 'js/tools/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import DeviceServices from 'js/data/device-services';

const MessagesDashboard = () => {
    const [form] = Form.useForm();
    const utils = new Utils();
    const currentLanguage = i18n.language;
    const [messagesFe, setMessagesFe] = useState<MessagesTimeSlot[]>([]);
    const [messagesDeliver, setMessagesDeliver] = useState<MessagesTimeSlot[]>([]);
    const [messagesClient, setMessagesClient] = useState<MessagesTimeSlot[]>([]);
    const [dateFilter, setDateFilter] = useState<moment.Moment>(moment());

    useEffect(() => {
        const getMessages = async () => {
            setMessagesFe(await DeviceServices.getFromDeviceToFe());
            setMessagesDeliver(await DeviceServices.getFromFeToDeliver());
            setMessagesClient(await DeviceServices.getFromDeliverToClient());
        };

        getMessages();
    }, [dateFilter]);

    return (
        <>
            <Row justify="space-between" align="middle">
                <Col xs={24} sm={12}>
                    <h2 className={styles.title}>Dashboard</h2>
                </Col>
                <Col xs={24} sm={12} className={styles['date-container']}>
                    <Form form={form} layout="vertical">
                        <button
                            className={styles['date-button-prev']}
                            onClick={() => {
                                setDateFilter(moment(dateFilter).subtract(1, 'days'));
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <DatePicker
                            format={DateFormats[currentLanguage]}
                            allowClear={false}
                            value={dateFilter}
                            disabledDate={utils.DisableDateOfFuture}
                            onChange={(value) => {
                                setDateFilter(value!);
                            }}
                        />
                        <button
                            className={styles['date-button-next']}
                            onClick={() => {
                                if (moment(dateFilter).add(1, 'days').isAfter(moment())) return;
                                setDateFilter(moment(dateFilter).add(1, 'days'));
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </Form>
                </Col>
            </Row>
            <Divider />
            <Row gutter={25}>
                <Col sm={24} lg={16}>
                    <MessagesTable deviceToFe={messagesFe} feToDeliver={messagesDeliver} deliverToClient={messagesClient} />
                </Col>
                <Col sm={24} lg={8}>
                    <MessagesNumbers deviceToFe={messagesFe} feToDeliver={messagesDeliver} deliverToClient={messagesClient} />
                </Col>
            </Row>
            <Row gutter={25}>
                <Col xs={0} sm={0} md={24}>
                    <MessagesLineChart deviceToFe={messagesFe} feToDeliver={messagesDeliver} deliverToClient={messagesClient} />
                </Col>
            </Row>
            <Row gutter={25}>
                <Col xs={0} sm={0} md={24}>
                    <MessagesBarChart deliverToClient={messagesClient} />
                </Col>
            </Row>
        </>
    );
};

export default MessagesDashboard;
