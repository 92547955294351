import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Divider, Drawer, Row, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FlowItem } from 'js/constants/types/flow';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import { FLOW_PROPERTIES } from 'js/constants/resources/flow-properties';
import { useTranslation } from 'react-i18next';
import i18n from 'js/tools/i18n';
import FlowServices from 'js/data/flow-services';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    selectedFlow: FlowItem;
    selectedCompany: number | undefined;
    data: FlowItem[];
}

const FlowViewSidebar = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const [historicMovements, setHistoricMovements] = useState<FlowItem[]>([]);

    const columns = [
        {
            title: t('flows.policyNumber'),
            dataIndex: 'policyNumber',
            key: 1,
        },
        {
            title: t('flows.movementType'),
            dataIndex: 'movementType',
            key: 2,
        },
        {
            title: t('flows.customerName'),
            dataIndex: 'customerName',
            key: 3,
        },
        {
            title: t('flows.customerFiscalId'),
            dataIndex: 'customerFiscalId',
            key: 4,
        },
        {
            title: t('flows.licensePlate'),
            dataIndex: 'licensePlate',
            key: 5,
        },
        {
            title: t('flows.product'),
            dataIndex: 'product',
            key: 6,
        },
        {
            title: t('flows.startServiceDate'),
            dataIndex: 'startServiceDate',
            key: 7,
            render: (date) => moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]),
        },
        {
            title: t('flows.endServiceDate'),
            dataIndex: 'endServiceDate',
            key: 8,
            render: (date) => moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]),
        },
        {
            title: t('flows.insertDate'),
            dataIndex: 'insertDate',
            key: 9,
            render: (date) => moment(date).format(DateFormats[currentLanguage]),
        },
        {
            title: t('flows.outcome'),
            dataIndex: 'outcome',
            key: 10,
        },
    ];

    const getHistoricMovements = async () => {
        if (props.selectedCompany && props.selectedFlow.voucherNumber) {
            const movements = await FlowServices.getFlowsHistoricMovements(
                props.selectedCompany!,
                props.selectedFlow.customerFiscalId!,
            );

            if (movements instanceof Response) {
                setHistoricMovements([]);
            } else {
                setHistoricMovements(movements);
            }
        } else {
            setHistoricMovements([]);
        }
    };

    useEffect(() => {
        getHistoricMovements();
    }, [props.visible]);

    return (
        <Drawer
            placement="right"
            closeIcon={<FontAwesomeIcon icon={faTimes} className="menu-icon anticon" color={'#1890ff'} />}
            width={window.innerWidth > 1020 ? 1000 : '85%'}
            zIndex={99999}
            onClose={() => {
                props.setVisible(false);
            }}
            visible={props.visible}
        >
            <h4 className={styles['sidebar-title']}>{t('flows.flowDetails')}</h4>
            <Row gutter={[16, 7]}>
                {Object.entries(props.selectedFlow).map(([key, value], index) => {
                    if (!FLOW_PROPERTIES?.get(key)) return;

                    return (
                        <Col key={index} span={8}>
                            <p className={styles['sidebar-label']}>
                                {FLOW_PROPERTIES?.get(key)}: <span className={styles['sidebar-value']}> {value}</span>
                            </p>
                        </Col>
                    );
                })}
            </Row>

            <Divider />

            <Row gutter={[16, 7]}>
                <Col span={8}>
                    <p className={styles['sidebar-label']}>
                        {t('flows.spoolDate')}:
                        <span className={styles['sidebar-value']}>
                            {moment(props.selectedFlow.spoolDate).format(DateFormats[currentLanguage])}
                        </span>
                    </p>
                </Col>
                <Col span={8}>
                    <p className={styles['sidebar-label']}>
                        {t('flows.outcome')}: <span className={styles['sidebar-value']}> {props.selectedFlow.outcome}</span>
                    </p>
                </Col>
                <Col span={8}>
                    <p className={styles['sidebar-label']}>
                        {t('flows.errorDescription')}:
                        <span className={styles['sidebar-value']}> {props.selectedFlow.errorDescription}</span>
                    </p>
                </Col>
            </Row>

            <Divider />

            <h4 className={styles['sidebar-title']}>{t('flows.flowHistory')}</h4>
            <Table
                className={styles['sidebar-table']}
                columns={columns}
                size="small"
                pagination={false}
                scroll={{ y: 240 }}
                rowKey="insertDate"
                dataSource={historicMovements}
            ></Table>
        </Drawer>
    );
};

export default FlowViewSidebar;
