import React from 'react';
import moment from 'moment';
import { Alert, message } from 'antd';
import { StringFormats, ValidationPatterns } from '../config/config';
import Contract from '../classes/contract';

const { shortDateFormat, monthYearShortDateFormat } = StringFormats;
const { fiscalCodePattern, vatIdPattern, visaCardPattern, amexCardPattern, masterCardPattern } = ValidationPatterns;

class Utils {
    private t: any;

    constructor(t?: any) {
        this.t = t;
    }

    Compare(a: simpleStruct, b: simpleStruct) {
        const nameA = a.label!.toUpperCase(); // ignora maiuscole e minuscole
        const nameB = b.label.toUpperCase(); // ignora maiuscole e minuscole
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // i nomi devono essere uguali
        return 0;
    }

    ValidateFiscalCode = async (_: any, value: any) => {
        if (value.length === 16) {
            if (fiscalCodePattern.test(value)) return Promise.resolve();
            else return Promise.reject(new Error());
        } else return Promise.reject(new Error());
    };

    ValidateVatNumber = async (_: any, value: any) => {
        if (value.length === 11) {
            if (vatIdPattern.test(value)) return Promise.resolve();
            else return Promise.reject(new Error());
        } else return Promise.reject(new Error());
    };

    ValidateFiscalId = async (_: any, value: any) => {
        if (value.length === 16) {
            if (fiscalCodePattern.test(value)) return Promise.resolve();
            else return Promise.reject(new Error());
        } else if (value.length === 11) {
            if (vatIdPattern.test(value)) return Promise.resolve();
            else return Promise.reject(new Error());
        } else return Promise.reject(new Error());
    };

    ValidateCard = async (_: any, value: any) => {
        if (visaCardPattern.test(value)) return Promise.resolve();
        else if (amexCardPattern.test(value)) return Promise.resolve();
        else if (masterCardPattern.test(value)) return Promise.resolve();
        else if (value.length === 0) return Promise.reject(new Error(this.t('errorMessages.cardNumberReq')));
        else return Promise.reject(new Error(this.t('errorMessages.creditCardValid')));
    };

    GetCreditCardType = (e: any, formRef: any) => {
        let circuitId: any, circuit: any;
        if (visaCardPattern.test(e.target.value)) {
            circuitId = '1';
            circuit = 'Visa';
        } else if (masterCardPattern.test(e.target.value)) {
            circuitId = '2';
            circuit = 'MasterCard';
        } else if (amexCardPattern.test(e.target.value)) {
            circuitId = '3';
            circuit = 'American Express';
        } else {
            circuitId = undefined;
            for (let i = 1; i < 4; i++) {
                document
                    .getElementById('circuit-' + i)!
                    .querySelector('img')!
                    .classList.add('image-disabled');
            }
        }
        if (circuitId !== undefined) {
            document
                .getElementById('circuit-' + circuitId)!
                .querySelector('img')!
                .classList.remove('image-disabled');
        }
        formRef.current!.setFieldsValue({ circuitId: circuitId });
        document.getElementById('circuitId')!.setAttribute('data-circuit', circuit);
    };

    DisableDateOfFuture = (current: any) => {
        return current > moment().endOf('day');
    };

    DisableDateAgeOfMajority = (current: any) => {
        return current > moment().endOf('day').add(-18, 'year');
    };

    DisableDateOfPresentAndPassedMonth = (current: any) => {
        return current < moment().endOf('month');
    };

    RenderMessageAlert(type: typeOfAlert, message?: string) {
        if (message === undefined) {
            return null;
        } else {
            return <Alert message={message} type={type} showIcon />;
        }
    }

    SetOptionSelected(id: string, checkId: string) {
        document.getElementById(id)!.style.color = '#389e0d';
        document.getElementById(id)!.style.background = '#f6ffed';
        document.getElementById(id)!.style.borderColor = '#b7eb8f';
        document.getElementById(id)!.style.fontWeight = '600';
        document.getElementById(checkId)!.hidden = false;
    }

    SetOptionUnselected(id: string, checkId: string) {
        document.getElementById(id)!.style.color = 'rgba(0, 0, 0, 0.85)';
        document.getElementById(id)!.style.background = '#fff';
        document.getElementById(id)!.style.borderColor = 'rgb(217, 217, 217)';
        document.getElementById(id)!.style.fontWeight = '400';
        document.getElementById(checkId)!.hidden = true;
    }

    PreparePractice(practice: Contract, userId: string, initiative: string) {
        switch (initiative) {
            case 'SEP': {
                //set default fields
                const contract: any = {
                    id_configurazione: practice.package.configurationId,
                    opzioni: practice.package.options,
                    data_contratto: moment().format(shortDateFormat),
                    societa: practice.customer.isBusiness.toString(),
                    telefono1: practice.customer.tel1,
                    telefono2: practice.customer.tel2,
                    email: practice.customer.email,
                    targa: practice.vehicle.licensePlate,
                    marca: practice.vehicle.brandId,
                    modello: practice.vehicle.model,
                    data_immatr: practice.vehicle.registrationDate?.format(shortDateFormat),
                    valore: practice.vehicle.value.toString(),
                    colore: practice.vehicle.colorId,
                    tipo_veicolo: '1',
                    nome_contatto: practice.contact.firstname + ' ' + practice.contact.surname,
                    telefono1_contatto: practice.contact.tel1,
                    telefono2_contatto: practice.contact.tel2,
                    email_contatto: practice.contact.email,
                    agenzia: userId,
                    tipo_pagamento: practice.payment.paymentTypeId,
                    intestatario: practice.payment.accountHolder,
                };

                //check if is professional
                if (practice.package.professional) {
                    //if true set installer
                    contract.codice_installatore = practice.installer.installerId;
                } else {
                    //if false check if shipping and residence are not equal
                    if (
                        (!(
                            practice.shipping.cityId === practice.customer.cityResidenceId &&
                            practice.shipping.provinceId === practice.customer.provinceResidenceId &&
                            practice.shipping.postalCode === practice.customer.postalCodeResidence &&
                            practice.shipping.address === practice.customer.addressResidence
                        ) &&
                            practice.customer.isBusiness === 0) ||
                        (!(
                            practice.shipping.cityId === practice.customer.citySocietyId &&
                            practice.shipping.provinceId === practice.customer.provinceSocietyId &&
                            practice.shipping.postalCode === practice.customer.postalCodeSociety &&
                            practice.shipping.address === practice.customer.addressSociety
                        ) &&
                            practice.customer.isBusiness === 1)
                    ) {
                        contract.indirizzo_spedizione = practice.shipping.address;
                        contract.cap_spedizione = practice.shipping.postalCode;
                        contract.citta_spedizione = practice.shipping.cityId;
                        contract.provincia_spedizione = practice.shipping.provinceId;
                    }
                }

                //check if is a phisical person
                if (practice.customer.isBusiness === 0) {
                    //if true set all person data
                    contract.nome = practice.customer.firstname + ' ' + practice.customer.surname;
                    contract.data_nascita = practice.customer.dateOfBirth?.format(shortDateFormat);
                    contract.luogo_nascita = practice.customer.cityOfBirthId;
                    contract.tipo_documento = practice.customer.docTypeId;
                    contract.numero_documento = practice.customer.docNumber;
                    contract.rilasciato_da = practice.customer.releasedBy;
                    contract.data_rilascio = practice.customer.releasedOn?.format(shortDateFormat);
                    contract.indirizzo = practice.customer.addressResidence;
                    contract.cap = practice.customer.postalCodeResidence;
                    contract.citta = practice.customer.cityResidenceId;
                    contract.provincia = practice.customer.provinceResidenceId;
                    contract.piva_cf = practice.customer.fiscalId;
                } else {
                    //if false set data for the company
                    contract.nome = practice.customer.businessName;
                    contract.piva_cf = practice.customer.vatNumber;
                    contract.pec = practice.customer.pec;
                    contract.sdi = practice.customer.sdi;
                    contract.indirizzo = practice.customer.addressSociety;
                    contract.cap = practice.customer.postalCodeSociety;
                    contract.citta = practice.customer.citySocietyId;
                    contract.provincia = practice.customer.provinceSocietyId;
                }

                //check for the payment type
                if (practice.payment.paymentTypeId.toString() === '1') {
                    contract.numero_carta = practice.payment.cardNumber;
                    contract.scadenza_carta = practice.payment.cardExpirationDate?.format(monthYearShortDateFormat);
                    contract.circuito = practice.payment.circuitId;
                } else if (practice.payment.paymentTypeId.toString() === '11') {
                    contract.iban = practice.payment.iban;
                    contract.firmatario = practice.payment.accountSignatory;
                    contract.cf_firmatario = practice.payment.fiscalId;
                    contract.indirizzo_intestatario = practice.payment.address;
                    contract.citta_intestatario = practice.payment.cityId;
                    contract.cap_intestatario = practice.payment.postalCode;
                    contract.sigla_nazione = practice.payment.nationId;
                    contract.codice_swift = practice.payment.swift;
                    contract.provincia_intestatario = practice.payment.province;
                }
                return contract;
            }
            default:
                return null;
        }
    }

    static ShowMessage(type: string, content: string) {
        message[type]({
            content: content,
            duration: 5,
        });
    }
}

export { Utils };
