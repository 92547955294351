import React, { useState } from 'react';
import styles from 'assets/styles/modules/agencies-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, Space } from 'antd';
import FlowServices from 'js/data/flow-services';
import { AgencyFlow } from 'js/constants/types/flow';
import { useStore } from 'store';
import AgenciesFlowTable from 'js/components/agencies-flow/agencies-flow-table';
import AgenciesFlowEditSidebar from 'js/components/agencies-flow/agencies-flow-edit-sidebar';
import AgenciesFlowFilters from 'js/components/agencies-flow/agencies-flow-filters';
import moment from 'moment';
import { FileType } from 'js/constants/enum/fileType';
import { AgencyFlowSearch } from 'js/constants/types/search';

const AgenciesFlowManagement = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [agenciesFlow, setAgenciesFlow] = useState<AgencyFlow[]>([]);
    const [visibleAgencyEditSidebar, setvisibleAgencyEditSidebar] = useState<boolean>(false);
    const [selectedAgencyFlow, setAgencySelectedFlow] = useState<AgencyFlow>({});

    const onEdit = async (event: any, record: AgencyFlow) => {
        setLoading(true);

        event.stopPropagation();
        const search: AgencyFlowSearch = { companyId : selectedCompany ?? 0, agencyCode :record.agencyCode!};
        setAgencySelectedFlow(await FlowServices.getSingleAgencyFlow(search!));
        setvisibleAgencyEditSidebar(true);

        setLoading(false);
    };

    const onDownloadFile = async (event: any, fileType: FileType) => {
        const formValues = form.getFieldsValue();
        const formatDate = 'DD-MM-YYYY';
        const todayDate = new Date();

        event.stopPropagation();
        setLoading(true);
        await FlowServices.getAgenciesFlowsFile('Agencies_Flows_' + moment(todayDate).format(formatDate),fileType, formValues);
        setLoading(false);
    };

    const refreshAgenciesFlows = async () => {
        const formValues = form.getFieldsValue();

        setLoading(true);
        setAgenciesFlow(await FlowServices.getAgenciesFlow(formValues));
        setvisibleAgencyEditSidebar(false);
        setLoading(false);
    };

    // useEffect(() => {
    //     getCompanies();
    // }, []);

    return (
        <>
            <h2 className={styles.title}>{t('commonClass.agenciesFlowManagement')}</h2>
            <section className={styles.filters}>
                <AgenciesFlowFilters
                    setSelectedCompany={setSelectedCompany}
                    setAgenciesFlows={setAgenciesFlow}
                    form={form}
                />
            </section>
            <AgenciesFlowTable agenciesFlow={agenciesFlow} onEdit={onEdit} downloadFile={onDownloadFile} />
            <AgenciesFlowEditSidebar
                visible={visibleAgencyEditSidebar}
                setVisible={setvisibleAgencyEditSidebar}
                selectedAgencyFlow={selectedAgencyFlow}
                data={agenciesFlow}
                refreshFlows={refreshAgenciesFlows}
                selectedCompany={selectedCompany}
            />
        </>
    );
};

export default AgenciesFlowManagement;
