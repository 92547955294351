import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, message, Modal, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FlowItem } from 'js/constants/types/flow';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { FLOW_PROPERTIES } from 'js/constants/resources/flow-properties';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import FlowServices from 'js/data/flow-services';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    selectedFlow: FlowItem;
    data: FlowItem[];
    refreshFlows: () => void;
    selectedCompany: number | undefined;
}

const FlowEditSidebar = (props: IProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);
    const [flowData, setFlowData] = useState<FlowItem>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const onSubmit = async (flow: FlowItem) => {
        setFlowData(flow);
        setModalVisible(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        await updateFlow();
        setModalVisible(false);
        setConfirmLoading(false);
    };

    const updateFlow = async () => {
        setLoading(true);
        const response = await FlowServices.updateFlow(props.selectedCompany!, flowData!);

        if (!(response instanceof Response)) {
            message.success(t('flows.uploadedSuccessfully'));
        }
        setLoading(false);
        props.refreshFlows();
    };

    useEffect(() => {
        form.setFieldsValue(props.selectedFlow);
    }, [props.selectedFlow]);

    return (
        <Drawer
            title={'Edit'}
            placement="right"
            getContainer={false}
            closeIcon={<FontAwesomeIcon icon={faTimes} className="menu-icon anticon" color={'#1890ff'} />}
            width={window.innerWidth > 1020 ? 540 : '85%'}
            zIndex={99999}
            onClose={() => {
                props.setVisible(false);
            }}
            visible={props.visible}
        >
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Row gutter={[16, 7]}>
                    {Object.entries(props.selectedFlow).map(([key], index) => {
                        return (
                            <Col key={index} span={12} style={FLOW_PROPERTIES?.get(key) ? {} : { display: 'none' }}>
                                <Form.Item label={FLOW_PROPERTIES?.get(key)} name={key}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
                <Form.Item>
                    <Button className={styles['edit-button']} type="primary" htmlType="submit">
                        {t('flows.save')}
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                title={t('flows.confirmUpdateTitle')}
                visible={modalVisible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => setModalVisible(false)}
                zIndex={999999}
                okText={t('flows.confirm')}
                cancelText={t('flows.cancel')}
                getContainer={false}
            >
                <p>{t('flows.confirmUpdateText')}</p>
            </Modal>
        </Drawer>
    );
};

export default FlowEditSidebar;
