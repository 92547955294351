import React, { useEffect } from 'react';
import styles from 'assets/styles/modules/agencies-flow-management.module.scss';
import { Table } from 'antd';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StringFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import { FileType } from 'js/constants/enum/fileType';
import DownoadFileButtons from '../donwload-file';
import ReportsGrids from './reports-grids';

const { shortDateFormat } = StringFormats;

interface IProps {
    reportResult?: any[];
    // onEdit: any;
    selectedReport: any;
    downloadFile: (event, fileType: FileType) => Promise<void>;
}

const ReportsTable = (props: IProps) => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const currentLanguage = i18n.language;

    const buttonsDownload = [
        {
            type: FileType.Excel,
            text: 'xlsx',
            disabled: props.reportResult ? false : true,
            tooltip: t('commonClass.downloadXlsx'),
            order: 10,
            key: 1,
        }
    ];

    // const GetColumns = (id: any) => {
    //     return ReportsGrids.getGrid(id);
    // }

    const GetColumns = (id: any) => {
        switch (id) {
            case 327:
            case 328:
            case 329:
            case 330:
                return [
                    {
                        title: t('reports.numeroVoucher'),
                        dataIndex: 'numeroVoucher',
                        key: 1,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.numeroPolizza'),
                        dataIndex: 'numeroPolizza',
                        key: 2,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.idMovimento'),
                        dataIndex: 'idMovimento',
                        key: 3,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataOperazione'),
                        dataIndex: 'dataOperazione',
                        key: 4,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataPresoApp'),
                        dataIndex: 'dataPresoApp',
                        key: 5,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.targa'),
                        dataIndex: 'targa',
                        key: 6,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.nome'),
                        dataIndex: 'nome',
                        key: 7,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.cognome'),
                        dataIndex: 'cognome',
                        key: 8,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.vat'),
                        dataIndex: 'vat',
                        key: 9,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.nomeFile'),
                        dataIndex: 'nomeFile',
                        key: 10,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataSpool'),
                        dataIndex: 'dataSpool',
                        key: 11,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.idViasat'),
                        dataIndex: 'idViasat',
                        key: 12,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.agenzia'),
                        dataIndex: 'agenzia',
                        key: 13,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataAttivazione'),
                        dataIndex: 'dataAttivazione',
                        key: 14,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.spedito'),
                        dataIndex: 'spedito',
                        key: 15,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.codiceInstallatore'),
                        dataIndex: 'codiceInstallatore',
                        key: 16,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataSped'),
                        dataIndex: 'dataSped',
                        key: 17,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.tipoProdotto'),
                        dataIndex: 'tipoProdotto',
                        key: 18,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.terminaleProprio'),
                        dataIndex: 'terminaleProprio',
                        key: 19,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.codiceLast'),
                        dataIndex: 'codiceLast',
                        key: 20,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.decorrenza'),
                        dataIndex: 'decorrenza',
                        key: 21,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.dataInizioServizio'),
                        dataIndex: 'dataInizioServizio',
                        key: 22,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.dataFineServizio'),
                        dataIndex: 'dataFineServizio',
                        key: 23,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.descrizione'),
                        dataIndex: 'descrizione',
                        key: 24,
                        align: 'center' as const
                    }
                ];
            case 343:
                return [
                    {
                        title: t('reports.idMovimento'),
                        dataIndex: 'idMovimento',
                        key: 1,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.mov'),
                        dataIndex: 'mov',
                        key: 2,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.targa'),
                        dataIndex: 'targa',
                        key: 3,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.tipo'),
                        dataIndex: 'tipo',
                        key: 4,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataRichiesta'),
                        dataIndex: 'dataRichiesta',
                        key: 5,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.dataEsecuzione'),
                        dataIndex: 'dataEsecuzione',
                        key: 6,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.giorniRitardo'),
                        dataIndex: 'giorniRitardo',
                        key: 7,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.causaRitardo'),
                        dataIndex: 'causaRitardo',
                        key: 8,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.terminale'),
                        dataIndex: 'terminale',
                        key: 9,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dispositivo'),
                        dataIndex: 'dispositivo',
                        key: 10,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.statoImpianto'),
                        dataIndex: 'statoImpianto',
                        key: 11,
                        align: 'center' as const
                    },
                    {
                        title: t('reports.dataAttivazione'),
                        dataIndex: 'dataAttivazione',
                        key: 12,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.dataOperazione'),
                        dataIndex: 'dataOperazione',
                        key: 13,
                        align: 'center' as const,
                        render: (date: string) => moment(new Date(date)).format("DD/MM/YYYY"),
                    },
                    {
                        title: t('reports.giorniRitardoLav'),
                        dataIndex: 'giorniRitardoLav',
                        key: 14,
                        align: 'center' as const
                    },
                ];
        }
    };

    // TODO: aggiungere alla classe report una proprietà "Type" su cui effettuare lo switch
    //       appena verrà definita la nuova base dati per i report.
    if (props.reportResult && props.reportResult.length > 0 && props.selectedReport != 331 && props.selectedReport != 334
        && props.selectedReport != 336 && props.selectedReport != 337 && props.selectedReport != 338) {
        return (
            <>
                <DownoadFileButtons buttonsProperty={buttonsDownload} downloadFile={props.downloadFile} />
                <br></br>
                <section className={styles.table}>
                    <Table
                        // columns={columns}
                        columns={GetColumns(props.selectedReport)}
                        dataSource={props.reportResult}
                        rowKey="numeroVoucher"
                        pagination={{ pageSize: 8, showSizeChanger: false, position: ['bottomRight'] }}
                        size="small"
                        scroll={{ x: 800 }}
                        footer={() => (
                            <div className={styles.footer}>
                                {t('commonClass.total')}: {props.reportResult?.length || 0}
                            </div>
                        )}
                    ></Table>
                </section>
            </>
        );    
    } else if (props.reportResult // && props.reportResult.length > 0 
               && (props.selectedReport == 331 || props.selectedReport == 334 || props.selectedReport == 336 
                   || props.selectedReport == 337 || props.selectedReport == 338 || props.selectedReport == 344)) {
        return (
            <>
                <DownoadFileButtons buttonsProperty={buttonsDownload} downloadFile={props.downloadFile} />
                <br></br>
                <section className={styles.table}>
                    <p>{t('reports.clickDownloadBtn')}</p>
                </section>
            </>
        );    
    } else {
        return (
            <>                
                <section className={styles.table}>
                    <p>NO DATA</p>
                </section>
            </>
        );    
    }
};

export default ReportsTable;
