import React, { useState } from 'react';
import styles from 'assets/styles/modules/output-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { InstallationFlow, MatchResultFlow } from 'js/constants/types/flow';
import FlowInstallationsTable from 'js/components/output-flow/flow-installations-table';
import FlowMatchesTable from 'js/components/output-flow/flow-matches-table';
import OutputFlowFilters from 'js/components/output-flow/flow-filters';



const OutputFlowManagement = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm<{ companyId: number; licensePlate: string; voucherNumber: string; viasatId: string }>();
    const [installations, setInstallations] = useState<InstallationFlow[]>([]);
    const [matchResults, setMatchResults] = useState<MatchResultFlow[]>([]);

    return (
        <>
            <h2 className={styles.title}>{t('commonClass.outputFlowManagement')}</h2>
            <section className={styles.filters}>
                <OutputFlowFilters setInstallations={setInstallations} setMatchResults={setMatchResults}form={form} />
                {/* <Form form={form} onFinish={onSubmit} layout="vertical">
                    <Row gutter={20} align="bottom">
                        <Col span={5}>
                            <Form.Item name="companyId" label={t('flows.company')} rules={[{ required: true, message: '' }]}>
                                <Select>
                                    {companies.map((company) => {
                                        return (
                                            <Option key={company.companyId} value={company.companyId}>
                                                {company.companyName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="licensePlate" label={t('flows.licensePlate')} normalize={(value) => value.trim()}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="voucherNumber" label={t('flows.voucherNumber')} normalize={(value) => value.trim()}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="viasatId" label={t('flows.serialNumber')} normalize={(value) => value.trim()}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <Button className={styles.search} type="primary" htmlType="submit">
                                    {t('flows.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> */}
            </section>
            <FlowMatchesTable matchResults={matchResults} />
            <FlowInstallationsTable installations={installations} />
        </>
    );
};

export default OutputFlowManagement;
