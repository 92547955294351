import i18next from 'i18next';
import i18n from 'js/tools/i18n';

export let AGENCY_FLOW_PROPERTIES;

export const fieldsAgencyProperty = [
    { key: 'companyCode', type: 'text', readonly: true, order: 10 },
    { key: 'agencyCode', type: 'text', readonly: true, order: 20 },
    { key: 'businessName', type: 'text', readonly: false, order: 30 },
    { key: 'agencyName', type: 'text', readonly: false, order: 40 },
    { key: 'agencyContact',type: 'text', readonly: false, order: 50 },
    { key: 'vat', type: 'text',readonly: false, order: 60 },
    { key: 'address',type: 'text', readonly: false, order: 70 },
    { key: 'city', type: 'text',readonly: false, order: 80 },
    { key: 'province',type: 'text', readonly: false, order: 90 },
    { key: 'postalCode',type: 'text', readonly: false, order: 100 },
    { key: 'country',type: 'text', readonly: false, order: 110 },
    { key: 'phone1', type: 'text',readonly: false, order: 120},
    { key: 'phone2', type: 'text',readonly: false, order: 130 },
    { key: 'fax', type: 'text',readonly: false, order: 140 },
    { key: 'email',type: 'text', readonly: false, order: 150 },
    { key: 'statusDescription',type: 'text', readonly: false, order: 160 },
    { key: 'dateIns',type: 'date', readonly: true, order: 170 },
    { key: 'dateMod',type: 'date', readonly: true, order: 180 },
    { key: 'dateFile',type: 'date', readonly: true, order: 190 },
    
];

i18next.on('languageChanged init', function () {

    AGENCY_FLOW_PROPERTIES = new Map([
        ['companyCode', i18n.t('agenciesFlows.companyCode')],
        ['agencyCode', i18n.t('agenciesFlows.code')],
        ['address', i18n.t('agenciesFlows.address')],
        ['agencyName', i18n.t('agenciesFlows.name')],
        ['agencyContact', i18n.t('agenciesFlows.contact')],
        ['dateIns', i18n.t('agenciesFlows.dateIns')],
        ['dateMod', i18n.t('agenciesFlows.dateMod')],
        ['dateFile', i18n.t('agenciesFlows.dateFile')],
        ['email', i18n.t('agenciesFlows.email')],
        ['fax', i18n.t('agenciesFlows.fax')],
        ['name', i18n.t('agenciesFlows.name')],
        ['phone1', i18n.t('agenciesFlows.phone1')],
        ['phone2', i18n.t('agenciesFlows.phone2')], 
        ['province', i18n.t('agenciesFlows.province')],
        ['statusDescription', i18n.t('agenciesFlows.statusDescription')],
        ['businessName', i18n.t('agenciesFlows.businessName')],
        ['city', i18n.t('agenciesFlows.city')],
        ['postalCode', i18n.t('agenciesFlows.postalCode')],
        ['country', i18n.t('agenciesFlows.country')],
        ['vat', i18n.t('agenciesFlows.vat')],
    ]);
});
