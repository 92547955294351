import React from 'react';
import styles from 'assets/styles/modules/output-flow-management.module.scss';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { InstallationFlow } from 'js/constants/types/flow';
import moment from 'moment';
import { DateLongFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';

interface IProps {
    installations: InstallationFlow[];
}

const FlowInstallationsTable = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    const columns = [
        {
            title: t('flows.type'),
            dataIndex: 'type',
            key: 1,
        },
        {
            title: t('flows.policyNumber'),
            dataIndex: 'policyNumber',
            key: 2,
        },
        {
            title: t('flows.voucherNumber'),
            dataIndex: 'voucherNumber',
            key: 3,
        },
        {
            title: t('flows.licensePlate'),
            dataIndex: 'licensePlate',
            key: 4,
        },
        {
            title: t('flows.companyCode'),
            dataIndex: 'companyCode',
            key: 5,
        },
        {
            title: t('flows.systemStatus'),
            dataIndex: 'systemStatus',
            key: 6,
        },
        {
            title: t('flows.reason'),
            dataIndex: 'reason',
            key: 7,
        },
        {
            title: t('flows.activationDate'),
            dataIndex: 'activationDate',
            key: 8,
        },
        {
            title: t('flows.serialNumber'),
            dataIndex: 'serialNumber',
            key: 9,
        },
        {
            title: t('flows.deviceType'),
            dataIndex: 'deviceType',
            key: 10,
        },
        {
            title: t('flows.product'),
            dataIndex: 'product',
            key: 11,
        },
        {
            title: t('flows.certificationDate'),
            dataIndex: 'certificationDate',
            key: 12,
        },
        {
            title: t('flows.stateCode'),
            dataIndex: 'stateCode',
            key: 13,
        },
        {
            title: t('flows.movementId'),
            dataIndex: 'movementId',
            key: 14,
        },
        {
            title: t('flows.operationDate'),
            dataIndex: 'operationDate',
            key: 15,
        },
        {
            title: t('flows.additionalNote'),
            dataIndex: 'additionalNote',
            key: 16,
        },
        {
            title: t('flows.dateSpool'),
            dataIndex: 'dateSpool',
            key: 17,
            render: (date: string) => date ? moment(new Date(date)).format(DateLongFormats[currentLanguage]) : null,
        },
        {
            title: t('flows.filename'),
            dataIndex: 'filename',
            key: 18,
        },
    ];

    return (
        <section className={styles.table}>
            <h4 className={styles['table-title']}>{t('flows.installationsTitle')}</h4>
            <Table
                columns={columns}
                dataSource={props.installations}
                rowKey="movementId"
                pagination={{ pageSize: 5, showSizeChanger: false, position: ['bottomCenter'] }}
                size="small"
                scroll={{ x: 800 }}
                footer={() => (
                    <div className={styles.footer}>
                        {t('flows.installationsTotal')}: {props.installations?.length || 0}
                    </div>
                )}
            ></Table>
        </section>
    );
};

export default FlowInstallationsTable;
