import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import ReportServices from 'js/data/report-services';
import i18n from 'js/tools/i18n';
import { Button, Col, DatePicker, Form, FormInstance, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { Report } from 'js/constants/types/report';
import { DateFormats } from 'js/config/config';
import { Utils } from 'js/tools/utils';
import moment from 'moment';
import { ReportSearch } from 'js/constants/types/search';

const { Option } = Select;
const utils = new Utils();
const { RangePicker } = DatePicker;

interface IProps {
    setSelectedReport: (value: React.SetStateAction<any>) => void;
    setReportData: (value: React.SetStateAction<any[]>) => void;
    setDateFilter: (value: React.SetStateAction<[string, string] | undefined>) => void;
    setYearFilter: (value: React.SetStateAction<string | undefined>) => void;
    setYearMonthFilter: (value: React.SetStateAction<string | undefined>) => void;
    form: FormInstance<any>;
}

const ReportsFilters = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const setLoading = useStore((state) => state.setLoading);
    const [searchDisabled, setSearchDisabled] = useState<boolean>(true);
    const [rangeDisabled, setRangeDisabled] = useState<boolean>(true);
    const [yearDisabled, setYearDisabled] = useState<boolean>(true);
    const [yearMonthDisabled, setYearMonthDisabled] = useState<boolean>(true);
    const [reports, setReports] = useState<Report[]>();
    const [dateFilter, setDateFilter] = useState<[string, string]>();
    const [yearFilter, setYearFilter] = useState<string>();
    const [yearMonthFilter, setYearMonthFilter] = useState<string>();

    const getReportData = async (formValues: ReportSearch) => {
        const reportData = await ReportServices.getReportData(formValues);
        const error = reportData instanceof Response;

        if (!error) {
            props.setReportData(reportData);
        } else {
            props.setReportData([]);
        }
    };

    const onSubmit = async (formValues: ReportSearch) => {
        setLoading(true);
        if (dateFilter == undefined) {
            formValues.rangeDate = [moment().startOf('month').format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')];
        } else {
            formValues.rangeDate = dateFilter;
        }
        if (yearFilter == undefined) {
            formValues.year = new Date().getFullYear().toString();
        } else {
            formValues.year = yearFilter;
        }
        if (yearMonthFilter == undefined) {
            formValues.yearMonth = moment().format('YYYY/MM');
        } else {
            formValues.yearMonth = yearMonthFilter;
        }

        // filters values
        props.setDateFilter(formValues.rangeDate);
        props.setYearFilter(formValues.year);
        props.setYearMonthFilter(formValues.yearMonth);
        // TODO: da rivedere con un attributo del tipo report ad esempio GRID o TEMPLATE
        props.setSelectedReport(formValues.idReport);
        await getReportData(formValues);
        setLoading(false);
    };

    /*controlla che ci siano almeno 2 valori inseriti come parametri di ricerca */
    const checkSearchInputInfo = (changedValues, allValues: ReportSearch) => {
        let i = 0;

        for (const property in allValues) {
            if (allValues[property]) {
                i++;

                // if(property == 'reportType' && allValues[property] != undefined && allValues[property] != '') {
                //     getReports(allValues.reportType);
                // }
            }
        }

        if (i >= 1) {
            setSearchDisabled(false);
        } else {
            setSearchDisabled(true);
        }

        // TODO: da parametrizzare in modo migliore, magari come attributo del report scelto (ad esempio una lista di filtri)
        switch (allValues.idReport) {
            case 327:                
            case 328:                
            case 329:                
            case 330:
                setRangeDisabled(false);
                setYearDisabled(true);
                setYearMonthDisabled(true);
                break;
            case 331:
            case 334:
            case 336:
            case 337:
            case 338:
                setRangeDisabled(true);
                setYearDisabled(false);
                setYearMonthDisabled(true);
                break;
            case 343:
            case 344:
            case 345:
                setRangeDisabled(true);
                setYearDisabled(true);
                setYearMonthDisabled(false);
                break;
        }
    };

    // const getReports = async (type: string) => {
    const getReports = async () => {
        setReports(await ReportServices.getInsuranceReports());
    };

    useEffect(() => {
        getReports();
    }, [dateFilter]);

    return (
        <Form form={props.form} layout="vertical" onFinish={onSubmit} onValuesChange={checkSearchInputInfo}>
            <Row gutter={20} align="bottom">
                <Col span={6}>
                    <Form.Item name="idReport" label={t('reports.reportName')} rules={[{ required: true, message: '' }]}>
                        <Select>
                            {reports?.map((report) => {
                                return (
                                    <Option key={report.idReport} value={report.idReport}>
                                        {report.nomeReport}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={t('reports.dateRange')} name="rangeDate">    {/* rules={[{ required: true }]} */}
                        <Space direction="vertical" size={12}>
                            <RangePicker 
                                disabled={rangeDisabled}
                                className={styles['date-picker']}
                                format={DateFormats[currentLanguage]}
                                disabledDate={utils.DisableDateOfFuture}
                                allowClear={false}
                                defaultValue={[moment().startOf('month'), moment()]}
                                onChange={(value, dateString) => {
                                    setDateFilter(dateString);
                                }}
                            />                           
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label={t('reports.year')} name="year">
                        <Space direction="vertical" size={12}>
                            <DatePicker 
                                disabled={yearDisabled}
                                picker="year"
                                className={styles['date-picker']}
                                disabledDate={utils.DisableDateOfFuture}
                                allowClear={false}
                                defaultValue={moment()}
                                onChange={(value) => {
                                    setYearFilter(value?.format('YYYY'));
                                }}
                            />                        
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label={t('reports.yearMonth')} name="yearMonth">
                        <Space direction="vertical" size={12}>
                            <DatePicker 
                                disabled={yearMonthDisabled}
                                picker="month"
                                className={styles['date-picker']}
                                disabledDate={utils.DisableDateOfFuture}
                                allowClear={false}
                                defaultValue={moment()}
                                onChange={(value) => {
                                    setYearMonthFilter(value?.format('YYYY/MM'));
                                }}
                            />                        
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Button className={styles.button} type="primary" htmlType="submit" disabled={searchDisabled}>
                            {t('reports.search')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default React.memo(ReportsFilters);
