import { Button, Form, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FlowItem, FlowNote } from 'js/constants/types/flow';
import React, { useEffect } from 'react';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { MovementStatus } from 'js/constants/enum/movementStatus';
import FlowServices from 'js/data/flow-services';

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCompany: number | undefined;
    selectedFlow: FlowItem;
    refreshFlows: () => Promise<void>;
}

const FlowNotesModal = (props: IProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const setLoading = useStore((state) => state.setLoading);

    const getNote = async () => {
        if (props.selectedCompany && props.selectedFlow.movementId && props.visible) {
            setLoading(true);
            const note = await FlowServices.getFlowNote(props.selectedCompany!, props.selectedFlow.movementId!);
            setLoading(false);

            form.setFieldsValue({
                status: note?.status || MovementStatus.Open,
                text: note?.text,
            });
        }
    };

    const onSubmit = async () => {
        const formValues = form.getFieldsValue();
        const flow: FlowNote = {
            companyId: props.selectedCompany!,
            movementId: props.selectedFlow.movementId!,
            text: formValues.text,
            status: formValues.status,
        };

        setLoading(true);
        await FlowServices.updateFlowNote(flow);
        setLoading(false);

        props.setVisible(false);
        props.refreshFlows();
    };

    useEffect(() => {
        getNote();
    }, [props.visible]);

    return (
        <Modal
            title={`${t('flows.note')} (${props.selectedFlow.policyNumber})`}
            visible={props.visible}
            onOk={() => false}
            onCancel={() => props.setVisible(false)}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item label={t('flows.status')} name="status" rules={[{ required: true, message: `${t('flows.requiredStatus')}` }]}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={1}>{t('flows.open')}</Radio.Button>
                        <Radio.Button value={2}>{t('flows.closed')}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t('flows.note')} name="text" rules={[{ required: true, message: `${t('flows.requiredNote')}` }]}>
                    <TextArea className={styles['modal-textarea']} showCount maxLength={255} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className={styles['modal-save']}>
                        {t('flows.save')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FlowNotesModal;
