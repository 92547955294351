import { FileType } from 'js/constants/enum/fileType';
import { Company } from 'js/constants/types/company';
import { AgenciesFlowAnalytics, AgencyFlow, FlowAnalytics, FlowItem, FlowNote, InstallationFlow, MatchResultFlow, OutputFlowAnalytics } from 'js/constants/types/flow';
import { AgenciesFlowSearch, AgencyFlowSearch } from 'js/constants/types/search';
import UtilsServices from 'js/tools/utils-services';
import { useStore } from 'store';

/*
 *  Shared variables and methods
 */
const token = useStore.getState().user?.token;
const headers = new Headers({ Authorization: `Bearer ${token}`, 'content-type': 'application/json' });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

useStore.subscribe((state) => {
    headers.set('Authorization', `Bearer ${state.user?.token}`);
});

/*
 *  Services
 */
export default class FlowServices {
    public static getCompanies = async (): Promise<Company[]> => {
        const response = await fetch(`${baseUrl}/API/General/GetCompanies`, { headers });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFlowsAnalytics = async ({
        startDate = null,
        period = 3,
    }: {
        startDate: null | string;
        period?: number;
    }): Promise<FlowAnalytics[]> => {
        const response = await fetch(
            `${baseUrl}/API/AnalyticsFlows/GetInputMovFlowsAnalytics?period=${period}${startDate ? '&startDate=' + startDate : ''}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFlowsMovements = async (
        companyId: number,
        insertDate: string,
        licensePlate= '',
        customerName= '',
        onlyErrors: boolean,
        onElaboration: boolean
    ): Promise<FlowItem[]> => {
        const response = await fetch(
            `${baseUrl}/API/InputFlows/GetFlows?CompanyId=${companyId}&licensePlate=${licensePlate}&customerName=${customerName}&InsertDate=${insertDate}&OnlyErrors=${onlyErrors}&onElaboration=${onElaboration}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFlowData = async (companyId: number, movementId: string): Promise<FlowItem> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/GetSingleFlow?CompanyId=${companyId}&MovementId=${movementId}`, {
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFlowsHistoricMovements = async (
        companyId: number,
        fiscalId: string,
    ): Promise<FlowItem[]> => {
        const response = await fetch(
            `${baseUrl}/API/InputFlows/GetHistoric?CompanyId=${companyId}&CustomerFiscalId=${fiscalId}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static startElaborations = async (companyId: number): Promise<void> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/Elaborations?companyId=${companyId}`, {
            method: 'POST',
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static updateFlow = async (companyId: number, flow: FlowItem): Promise<void | Response> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/UpdateFlow?companyId=${companyId}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(flow),
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static reSyncMovement = async (companyId: number, movementId:string ): Promise<void | Response> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/ReSyncMovement?companyId=${companyId}&movementId=${movementId}`, {
            method: 'PUT',
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getFlowNote = async (companyId: number, movementId: string): Promise<FlowNote> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/GetNotes?CompanyId=${companyId}&MovementId=${movementId}`, {
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static updateFlowNote = async (note: FlowNote): Promise<void> => {
        const response = await fetch(`${baseUrl}/API/InputFlows/UpdateNotes`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(note),
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static outputFlowAnalytics = async ({
        startDate = null,
        period = 3,
    }: {
        startDate: null | string;
        period?: number;
    }): Promise<OutputFlowAnalytics[]> => {
        const response = await fetch(
            `${baseUrl}/API/AnalyticsFlows/GetOutputMovFlowsAnalytics/?period=${period}${startDate ? '&startDate=' + startDate : ''}`,
            {
                headers,
            }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static agenciesFlowAnalytics = async ({
        startDate = null,
        period = 3,
    }: {
        startDate: null | string;
        period?: number;
    }): Promise<AgenciesFlowAnalytics[]> => {
        const response = await fetch(
            `${baseUrl}/API/AnalyticsFlows/AgenciesFlowsAnalytics/?period=${period}${startDate ? '&startDate=' + startDate : ''}`,
            {
                headers,
            }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getInstallations = async (
        companyId: number,
        licensePlate?: string,
        voucherNumber?: string,
        deviceId?: string
    ): Promise<InstallationFlow[]> => {
        const response = await fetch(
            `${baseUrl}/API/OutputFlows/GetInstallationsFlows?CompanyId=${companyId}${licensePlate ? '&LicensePlate=' + licensePlate : ''}${
                voucherNumber ? '&VoucherNumber=' + voucherNumber : ''
            }${deviceId ? '&DeviceId=' + deviceId : ''}`,
            {
                headers,
            }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getMatchResults = async (
        companyId: number,
        licensePlate?: string,
        voucherNumber?: string
    ): Promise<MatchResultFlow[]> => {
        const response = await fetch(
            `${baseUrl}/API/OutputFlows/GetMatchResults?CompanyId=${companyId}${licensePlate ? '&LicensePlate=' + licensePlate : ''}${
                voucherNumber ? '&VoucherNumber=' + voucherNumber : ''
            }`,
            {
                headers,
            }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static downloadFile = async (filename: string, type: string, companyId: number) => {
        const response = await fetch(
            `${baseUrl}/API/OutputFlows/DownloadFile?filename=${filename}&fileType=${type}&companyId=${companyId}`,
            {
                headers 
            }
        );

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const temporaryLink = document.createElement('a');

        temporaryLink.href = url;
        temporaryLink.download = filename;
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        temporaryLink.remove();
    };

    public static resendFile = async (dtFile: Date, companyId: number, fileType: string): Promise<void | Response> => {
        const response = await fetch(`${baseUrl}/API/OutputFlows/ResendFile?dateFile=${dtFile}&companyId=${companyId}&fileType=${fileType}`, {
            method: 'PUT',
            headers,
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getAgenciesFlow = async (search: AgenciesFlowSearch): Promise<AgencyFlow[]> => {
        const response = await fetch(
            `${baseUrl}/API/AgenciesFlows/GetAllByCompany?companyId=${search.companyId}${
                search.agencyCode ? '&agencyCode=' + search.agencyCode : ''
            }${search.vat ? '&vat=' + search.vat : ''}${
                search.businessName ? '&businessName=' + search.businessName : ''
            }`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };


    public static getSingleAgencyFlow = async ( search: AgencyFlowSearch): Promise<AgencyFlow> => {
        const response = await fetch(
            `${baseUrl}/API/AgenciesFlows/GetSingleAgencyFlow?companyId=${search.companyId}&AgencyCode=${search.agencyCode}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getAgencyFlow = async (
        companyId: number | undefined
    ): Promise<AgencyFlow[]> => {
        const response = await fetch(
            `${baseUrl}/API/AgenciesFlows/GetAllByCompany?CompanyId=${companyId}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static agencyUpdateFlow = async (flow: AgencyFlow): Promise<void | Response>  => {
        const response = await fetch(`${baseUrl}/API/AgenciesFlows/UpdateAgencyFlow`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(flow),
        });
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static  getAgenciesFlowsFile =async (filename: string, fileType: FileType, search: AgenciesFlowSearch) => {
        const response = await fetch(
            `${baseUrl}/API/AgenciesFlows/GetAgenciesFlowsFile?filename=${filename}&filetype=${fileType}&companyId=${search.companyId}${
                search.agencyCode ? '&agencyCode=' + search.agencyCode : ''
            }${search.vat ? '&vat=' + search.vat : ''}${
                search.businessName ? '&businessName=' + search.businessName : ''
            }`,
            { headers }
        );
       
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const temporaryLink = document.createElement('a');

        temporaryLink.href = url;
        switch (fileType){
            case FileType.Csv:
                filename = filename + '.csv';
                break;
            case FileType.Excel:
                filename = filename + '.xlsx';
                break;
        }
       
        temporaryLink.download = filename;
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        temporaryLink.remove();        
      }
}
