import i18next from 'i18next';
import i18n from 'js/tools/i18n';

export let FLOW_PROPERTIES;

i18next.on('languageChanged init', function () {
    FLOW_PROPERTIES = new Map([
        ['policyNumber', i18n.t('flowConstants.policyNumber')],
        ['voucherNumber', i18n.t('flowConstants.voucherNumber')],
        ['companyCode', i18n.t('flowConstants.companyCode')],
        ['agencyCode', i18n.t('flowConstants.agencyCode')],
        ['installerCode', i18n.t('flowConstants.installerCode')],
        ['movementType', i18n.t('flowConstants.movementType')],
        ['installationType', i18n.t('flowConstants.installationType')],
        ['policyDate', i18n.t('flowConstants.policyDate')],
        ['operationDate', i18n.t('flowConstants.operationDate')],
        ['oldPolicyNumber', i18n.t('flowConstants.oldPolicyNumber')],
        ['oldInstallationType', i18n.t('flowConstants.oldInstallationType')],
        ['startServiceDate', i18n.t('flowConstants.startServiceDate')],
        ['endServiceDate', i18n.t('flowConstants.endServiceDate')],
        ['customerFiscalId', i18n.t('flowConstants.customerFiscalId')],
        ['customerName', i18n.t('flowConstants.customerName')],
        ['business', i18n.t('flowConstants.business')],
        ['address', i18n.t('flowConstants.address')],
        ['postalCode', i18n.t('flowConstants.postalCode')],
        ['city', i18n.t('flowConstants.city')],
        ['province', i18n.t('flowConstants.province')],
        ['licensePlate', i18n.t('flowConstants.licensePlate')],
        ['brand', i18n.t('flowConstants.brand')],
        ['model', i18n.t('flowConstants.model')],
        ['registrationDate', i18n.t('flowConstants.registrationDate')],
        ['oldLicensePlate', i18n.t('flowConstants.oldLicensePlate')],
        ['contactName', i18n.t('flowConstants.contactName')],
        ['phone1', i18n.t('flowConstants.phone1')],
        ['phone2', i18n.t('flowConstants.phone2')],
        ['paymentRule', i18n.t('flowConstants.paymentRule')],
        ['assistanceWarranty', i18n.t('flowConstants.assistanceWarranty')],
        ['emailAddress', i18n.t('flowConstants.emailAddress')],
        ['sagCode', i18n.t('flowConstants.sagCode')],
        ['annualKmDeclared', i18n.t('flowConstants.annualKmDeclared')],
        ['minRangeDeclared', i18n.t('flowConstants.minRangeDeclared')],
        ['maxRangeDeclared', i18n.t('flowConstants.maxRangeDeclared')],
        ['ppu', i18n.t('flowConstants.ppu')],
        ['reasonType', i18n.t('flowConstants.reasonType')],
        ['theftWarranty', i18n.t('flowConstants.theftWarranty')],
        ['companyId', i18n.t('flowConstants.companyId')],
        ['brandCode', i18n.t('flowConstants.brandCode')],
        ['providerId', i18n.t('flowConstants.providerId')],
        ['movementId', i18n.t('flowConstants.movementId')],
        ['sendDate', i18n.t('flowConstants.sendDate')],
        ['productType', i18n.t('flowConstants.productType')],
        ['correctionMovement', i18n.t('flowConstants.correctionMovement')],
        ['vehicleType', i18n.t('flowConstants.vehicleType')],
        ['roadAssistance', i18n.t('flowConstants.roadAssistance')],
        ['conventionCode', i18n.t('flowConstants.conventionCode')],
        ['algorithmVersion', i18n.t('flowConstants.algorithmVersion')],
        ['customerSurname', i18n.t('flowConstants.customerSurname')],
        ['shipmentSurname', i18n.t('flowConstants.shipmentSurname')],
        ['shipmentName', i18n.t('flowConstants.shipmentName')],
        ['shipmentAt', i18n.t('flowConstants.shipmentAt')],
        ['shipmentAddress', i18n.t('flowConstants.shipmentAddress')],
        ['shipmentPostalCode', i18n.t('flowConstants.shipmentPostalCode')],
        ['shipmentCity', i18n.t('flowConstants.shipmentCity')],
        ['shipmentMunicipality', i18n.t('flowConstants.shipmentMunicipality')],
        ['shipmentProvince', i18n.t('flowConstants.shipmentProvince')],
        ['vin', i18n.t('flowConstants.vin')],
    ]);
});
