export const OidcSettings = {
    authority: 'https://localhost:5000/',
    client_id: 'insurance.viasatonline.it',
    redirect_uri: 'http://localhost:3000/signin-oidc',
    response_type: 'code',
    response_mode: 'fragment',
    scope: 'openid roles',
    loadUserInfo: true,
    post_logout_redirect_uri: 'http://localhost:3000/signout-callback-oidc',
    client_secret: 'pluto',
};

export const ValidationPatterns = {
    carLicencePlatePattern: /[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}/g,
    postalCodePattern: /^\d{5}$/,
    emailPattern: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    ibanPattern:
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i,
    swiftPattern: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/g,
    amexCardPattern: /^3[47][0-9]{13}$/,
    visaCardPattern: /^4[0-9]{12}(?:[0-9]{3})?$/,
    masterCardPattern: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    fiscalCodePattern: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
    vatIdPattern: /^\d{11}/i,
    telefonNumberPattern: /^[^a-zA-Z]+$/,
};

export const StringFormats = {
    shortDateFormat: 'DD/MM/YYYY',
    shortDateFormatUS: 'MM/DD/YYYY',
    longDateFormat:'DD/MM/YYYY HH:mm:ss',
    longDateFormatUs:'MM/DD/YYYY HH:mm:ss',
    monthYearShortDateFormat: 'MM/YYYY',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
};

// export const ExternalUrls = {
//     viasatMiddleware: 'https://viasatmsghub.viasatonline.it',
//     openStreetMap: 'https://nominatim.openstreetmap.org',
//     viasatSSO: 'https://viasatmsghub.viasatonline.it',
// };

// export const DemoExternalUrls = {
//     viasatMiddleware: 'https://localhost:5003',
//     openStreetMap: 'https://nominatim.openstreetmap.org',
//     viasatSSO: 'https://localhost:5003',
// };

export const DateFormats = {
    'en-US': 'MM/DD/YYYY',
    'it-IT': 'DD/MM/YYYY',
};

export const DateLongFormats ={
    'en-US': 'MM/DD/YYYY h:mm:ss a',
    'it-IT': 'DD/MM/YYYY HH:mm:ss',
}
