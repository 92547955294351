import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Drawer, Form, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from 'assets/styles/modules/input-flow-management.module.scss';
import { useTranslation } from 'react-i18next';
import { DateFormats } from 'js/config/config';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { fieldsInvoiceProperty, INVOICE_ROWS_PROPERTIES } from 'js/constants/resources/invoices-properties';
import { BillRow } from 'js/constants/types/bill';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    selectedInvoicedRows?: BillRow;
}

const InvoiceRowViewSideBar = (props: IProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const currentLanguage = i18n.language;

    useEffect(() => {
        form.setFieldsValue(props.selectedInvoicedRows);
    }, [props.selectedInvoicedRows]);

    return (
        <Drawer
            placement="right"
            closeIcon={<FontAwesomeIcon icon={faTimes} className="menu-icon anticon" color={'#1890ff'} />}
            width={window.innerWidth > 1020 ? 1000 : '85%'}
            zIndex={99999}
            onClose={() => {
                props.setVisible(false);
            }}
            visible={props.visible}
        >
            <h4 className={styles['sidebar-title']}>{`${t('invoices.policyNumber')} ${props.selectedInvoicedRows?.policyNumber}`}</h4>
            <Row gutter={[16, 7]}>
                {fieldsInvoiceProperty
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((p) => {
                        switch (p.type) {
                            case 'text':
                                return (
                                    <Col key={p.order} span={12} style={INVOICE_ROWS_PROPERTIES?.get(p.key) ? {} : { display: 'none' }}>
                                        <p className={styles['sidebar-label']}>
                                            {INVOICE_ROWS_PROPERTIES?.get(p.key)}:{' '}
                                            <span className={styles['sidebar-value']}>
                                                {props.selectedInvoicedRows ? props.selectedInvoicedRows[p.key] : ''}
                                            </span>
                                        </p>
                                    </Col>
                                );
                                break;
                            case 'date':
                                return (
                                    <Col key={p.order} span={12} style={INVOICE_ROWS_PROPERTIES?.get(p.key) ? {} : { display: 'none' }}>
                                        <p className={styles['sidebar-label']}>
                                            {INVOICE_ROWS_PROPERTIES?.get(p.key)}:{' '}
                                            <span className={styles['sidebar-value']}>
                                                {' '}
                                                {props.selectedInvoicedRows
                                                    ? props.selectedInvoicedRows[p.key]
                                                        ? moment(props.selectedInvoicedRows[p.key], 'DD/MM/YYYY').format(
                                                              DateFormats[currentLanguage]
                                                          )
                                                        : ''
                                                    : ''}
                                            </span>
                                        </p>
                                    </Col>
                                );

                                break;
                        }
                    })}
            </Row>
        </Drawer>
    );
};

export default InvoiceRowViewSideBar;
