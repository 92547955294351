import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/modules/policies-management.module.scss';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import FlowServices from 'js/data/flow-services';
import { Company } from 'js/constants/types/company';
import PolicyServices from 'js/data/policy-services';
import { Policy, PolicySearch } from 'js/constants/types/policy';
import { useStore } from 'store';
import PoliciesManagementTable from 'js/components/policies-management/policies-management-table';
import PolicyUpdateActivationDateModal from 'js/components/policies-management/policy-update-activation-date-modal';
import { Utils } from 'js/tools/utils';

const { Option } = Select;

const PoliciesManagement = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [companies, setCompanies] = useState<Company[]>();
    const [policies, setPolicies] = useState<Policy[]>();
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [selectedPolicyNumber, setSelectedPolicyNumber] = useState<string>();
    const [selectedContractId, setSelectedContractId] = useState<string>('');
    const setLoading = useStore((state) => state.setLoading);
    const [searchDisable, setSearchDisable] = useState<boolean>(false);
    const [visibleUpdActivationDateModal, setVisibleUpdActivationModal] = useState<boolean>(false);

    const getPolicies = async (formValues: PolicySearch) => {
        const policies = await PolicyServices.getPolicies(formValues);
        const error = policies instanceof Response;

        if (!error) {
            setPolicies(policies);
        } else {
            setPolicies([]);
        }
    };

    /*controlla che ci siano almeno 2 valori inseriti come parametri di ricerca */
    const checkSearchInputInfo = (allValues: PolicySearch) => {
        let i = 0;

        for (const property in allValues) {
            if (allValues[property]) {
                i++;
            }
        }
        if (i > 1) {
            return true;
            // setSearchDisable(false);
        } else {
            return false;
            // setSearchDisable(true);
        }
    };

    const refreshPolicies = async () => {
        const formValues = form.getFieldsValue();

        setLoading(true);
        await getPolicies(formValues);
        setLoading(false);
    };

    const onEditActivationDate = async (event: any, record: Policy) => {
        setLoading(true);
        event.stopPropagation();
        setSelectedPolicyNumber(record.policyNumber);
        setSelectedContractId(record.contractId);

        setVisibleUpdActivationModal(true);

        setLoading(false);
    };

    const onSubmit = async (formValues: PolicySearch) => {
        setLoading(true);

        if (checkSearchInputInfo(formValues)) {
            setSelectedCompany(formValues.companyId);
            await getPolicies(formValues);
        } else {
            Utils.ShowMessage('error', t('flows.selectFilterError'));
        }
        setLoading(false);
    };

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <>
            <h2 className={styles.title}>{t('commonClass.policiesManagement')}</h2>
            <Row gutter={20}>
                <Col xs={24} sm={24} lg={24}>
                    <div className={styles.filters}>
                        <h4 className={styles.subtitle}>{t('policiesManagement.filters')}</h4>
                        <Form form={form} layout="vertical" onFinish={onSubmit}>
                            <Row gutter={20}>
                                <Col xs={24} sm={24} lg={4}>
                                    <Form.Item label={t('policiesManagement.company')} name="companyId" rules={[{ required: true }]}>
                                        <Select>
                                            {companies?.map((item) => {
                                                return (
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={4}>
                                    <Form.Item
                                        label={t('policiesManagement.policyNumber')}
                                        name="policyNumber"
                                        normalize={(value) => value.toUpperCase().trim()}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={4}>
                                    <Form.Item
                                        label={t('policiesManagement.customerName')}
                                        name="customerName"
                                        normalize={(value) => value.toUpperCase().trim()}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={4}>
                                    <Form.Item
                                        label={t('policiesManagement.licensePlate')}
                                        name="licensePlate"
                                        normalize={(value) => value.toUpperCase().trim()}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={4}>
                                    <Form.Item
                                        label={t('policiesManagement.voucher')}
                                        name="originVoucher"
                                        normalize={(value) => value.toUpperCase().trim()}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={4}>
                                    <Button className={styles.button} type="primary" htmlType="submit" disabled={searchDisable}>
                                        {t('policiesManagement.search')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                    <div className={styles.filters}>
                        <h4 className={styles.subtitle}>{t('policiesManagement.policies')}</h4>
                        <PoliciesManagementTable
                            onEditActivationDate={onEditActivationDate}
                            policies={policies}
                            company={selectedCompany}
                            setModalVisible={setVisibleUpdActivationModal}
                        />
                    </div>
                </Col>
            </Row>
            <PolicyUpdateActivationDateModal
                visible={visibleUpdActivationDateModal}
                setVisible={setVisibleUpdActivationModal}
                selectedCompany={selectedCompany}
                selectedPolicyNumber={selectedPolicyNumber}
                selectedContractId={selectedContractId}
                refreshPolicies={refreshPolicies}
            />
        </>
    );
};

export default PoliciesManagement;
