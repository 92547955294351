import React from 'react';
import styles from 'assets/styles/modules/output-flow-management.module.scss';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { MatchResultFlow } from 'js/constants/types/flow';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { DateLongFormats } from 'js/config/config';

interface IProps {
    matchResults: MatchResultFlow[];
}

const FlowMatchesTable = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    const columns = [
        {
            title: t('flows.type'),
            dataIndex: 'type',
            key: 1,
        },
        {
            title: t('flows.policyNumber'),
            dataIndex: 'policyNumber',
            key: 2,
        },
        {
            title: t('flows.voucherNumber'),
            dataIndex: 'voucherNumber',
            key: 3,
        },
        {
            title: t('flows.stateCode'),
            dataIndex: 'stateCode',
            key: 4,
        },
        {
            title: t('flows.movementId'),
            dataIndex: 'movementId',
            key: 5,
        },
        {
            title: t('flows.operationDate'),
            dataIndex: 'operationDate',
            key: 6,
        },
        {
            title: t('flows.result'),
            dataIndex: 'result',
            key: 7,
        },
        {
            title: t('flows.description'),
            dataIndex: 'description',
            key: 8,
        },
        {
            title: t('flows.companyCode'),
            dataIndex: 'companyCode',
            key: 9,
        },
        {
            title: t('flows.licensePlate'),
            dataIndex: 'licensePlate',
            key: 10,
        },
        {
            title: t('flows.business'),
            dataIndex: 'business',
            key: 11,
        },
        {
            title: t('flows.firstname'),
            dataIndex: 'firstname',
            key: 12,
        },
        {
            title: t('flows.surname'),
            dataIndex: 'surname',
            key: 13,
        },
        {
            title: t('flows.fiscalId'),
            dataIndex: 'fiscalId',
            key: 14,
        },
        {
            title: t('flows.dateSpool'),
            dataIndex: 'dateSpool',
            key: 15,
            render: (date: string) => (date ? moment(new Date(date)).format(DateLongFormats[currentLanguage]) : null),
        },
        {
            title: t('flows.filename'),
            dataIndex: 'filename',
            key: 16,
        },
    ];

    return (
        <section className={styles.table}>
            <h4 className={styles['table-title']}>{t('flows.matchTitle')}</h4>
            <Table
                columns={columns}
                dataSource={props.matchResults}
                rowKey="key"
                pagination={{ pageSize: 5, showSizeChanger: false, position: ['bottomCenter'] }}
                size="small"
                scroll={{ x: 800 }}
                footer={() => (
                    <div className={styles.footer}>
                        {t('flows.matchesTotal')}: {props.matchResults?.length || 0}
                    </div>
                )}
            ></Table>
        </section>
    );
};

export default FlowMatchesTable;
