import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { MessagesTimeSlot } from 'js/constants/types/messagesTimeSlot';
import styles from 'assets/styles/modules/messages-table.module.scss';

interface IProps {
    deviceToFe: MessagesTimeSlot[];
    feToDeliver: MessagesTimeSlot[];
    deliverToClient: MessagesTimeSlot[];
}

interface ISlot {
    id: number;
    totalFe?: number;
    totalDev?: number;
    totalCl?: number;
    sla60?: number;
    sla240?: number;
}

const columns = [
    {
        title: 'ID Slot',
        dataIndex: 'id',
        key: 1,
    },
    {
        title: 'Device → FE',
        dataIndex: 'totalFe',
        key: 2,
    },
    {
        title: 'FE → Deliver',
        dataIndex: 'totalDev',
        key: 3,
    },
    {
        title: 'Deliver → Client',
        dataIndex: 'totalCl',
        key: 4,
    },
    {
        title: 'SLA 60s',
        dataIndex: 'sla60',
        key: 5,
    },
    {
        title: 'SLA 240s',
        dataIndex: 'sla240',
        key: 6,
    },
];

const MessagesTable = (props: IProps) => {
    const [slots, setSlots] = useState<ISlot[]>([]);

    const mergeSlotArrays = () => {
        let mergedSlots: ISlot[] = [];

        // Fill Device → FE data
        mergedSlots = [...props.deviceToFe.map((item) => ({ id: item.id, totalFe: item.total }))];

        // Fill FE → Deliver data
        props.feToDeliver.forEach((slot) => {
            const index = mergedSlots.findIndex((item) => slot.id === item.id);

            if (index !== -1) {
                mergedSlots[index] = { ...mergedSlots[index], totalDev: slot.total };
            } else {
                // add new slot if it's missing
                mergedSlots.push({ id: slot.id, totalDev: slot.total });
            }
        });

        // Fill Deliver → Client data
        props.deliverToClient.forEach((slot) => {
            const index = mergedSlots.findIndex((item) => slot.id === item.id);

            if (index !== -1) {
                mergedSlots[index] = { ...mergedSlots[index], totalCl: slot.total, sla60: slot.sla60, sla240: slot.sla240 };
            } else {
                // add new slot if it's missing
                mergedSlots.push({ id: slot.id, totalCl: slot.total, sla60: slot.sla60, sla240: slot.sla240 });
            }
        });

        return mergedSlots;
    };

    const getColumnValue = (value: number | undefined) => {
        if (value !== undefined) {
            return value;
        }

        return '-';
    };

    useEffect(() => {
        setSlots(mergeSlotArrays());
    }, [props.deviceToFe, props.feToDeliver, props.deliverToClient]);

    return (
        <div className={styles['messages-table']}>
            <h4 className={styles['messages-table__title']}>Messages Table</h4>
            <Table
                columns={columns}
                size="small"
                pagination={{ pageSize: 5 }}
                dataSource={slots.map((slot, index) => ({
                    ...slot,
                    key: index,
                    totalFe: getColumnValue(slot.totalFe),
                    totalDev: getColumnValue(slot.totalDev),
                    totalCl: getColumnValue(slot.totalCl),
                    sla60: getColumnValue(slot.sla60),
                    sla240: getColumnValue(slot.sla240),
                }))}
            ></Table>
        </div>
    );
};

export default MessagesTable;
