import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, message, Select } from 'antd';
import styles from 'assets/styles/modules/invoices.module.scss';
import { useTranslation } from 'react-i18next';
import { Company } from 'js/constants/types/company';
import FlowServices from 'js/data/flow-services';
import BillingServices from 'js/data/billing-services';
import { OutputFile } from 'js/constants/types/outputFile';
import { Bill, BillRow } from 'js/constants/types/bill';
import InvoicesModal from 'js/components/invoices/invoices-modal';
import InvoicesDetails from 'js/components/invoices/invoices-details';
import InvoiceRowsTable from 'js/components/invoices/invoices-table';
import InvoicesFilters from 'js/components/invoices/invoices-filter';
import { useStore } from 'store';
import { FileType } from 'js/constants/enum/fileType';
import InvoiceRowViewSideBar from 'js/components/invoices/invoice-view-sidebar';
import moment from 'moment';
import { BillingsFlowsSearch, InvoiceSearch } from 'js/constants/types/search';

const Invoices: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [selectedFile, setSelectedFile] = useState<OutputFile>();
    const [visibleViewSidebar, setVisibleViewSidebar] = useState<boolean>(false);
    const [selectedFlow, setSelectedFlow] = useState<BillRow>();
    const [bill, setBill] = useState<Bill>();
    const setLoading = useStore((state) => state.setLoading);

    const getCompanies = async () => {
        setCompanies(await FlowServices.getCompanies());
    };

    const processInvoice = () => {
        // No await because it can take a long time.
        BillingServices.processInvoice(selectedCompany!, bill?.invoiceNumber || '');
        message.success(t('invoices.processingStarted'));
    };

    const onViewInvoiceRow = async (event: any, record: BillRow) => {
        setLoading(true);

        event.stopPropagation();
        setSelectedFlow(record);
        setVisibleViewSidebar(true);

        setLoading(false);
    };

    const onDownloadFile = async (event: any, fileType: FileType) => {
        const formatDate = 'DD-MM-YYYY';
        const todayDate = new Date();

        const search: BillingsFlowsSearch = { companyId: selectedCompany ?? 0, invoiceNumber: bill?.invoiceNumber ?? '' };
        const fileName = selectedFile?.fileName ?? bill?.invoiceNumber;

        event.stopPropagation();
        setLoading(true);
        await BillingServices.getBillingsFlowsFile(fileName ?? 'Billings_Flows_' + moment(todayDate).format(formatDate), fileType, search);
        setLoading(false);
    };

    const onRefreshStatusInvoice = async () => {
        if (bill?.invoiceNumber != undefined && selectedCompany != undefined) {
            const search: BillingsFlowsSearch = { companyId: selectedCompany ?? 0, invoiceNumber: bill?.invoiceNumber ?? '' };
            const response = await BillingServices.getBillingFile(search);
            const error = response instanceof Response;

            if (!error) {
                const file = response[0];

                if (selectedFile?.status != file.status) {
                    const search: InvoiceSearch = { companyId: selectedCompany ?? 0, fileName: bill?.invoiceNumber ?? '' };
                    await getInvoices(search);
                }

                setSelectedFile(file);
            }
        }
    };

    const getInvoices = async (search: InvoiceSearch) => {
        const response = await BillingServices.getBillingDetails(search.companyId!, search.fileName!);
        const error = response instanceof Response;

        if (!error) {
            setBill(response[0]);
        }
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <>
            {/* Header */}
            <div className={styles.header}>
                <h2 className={styles.title}>{t('commonClass.invoices')}</h2>
                <Button type="primary" onClick={() => setIsModalVisible(true)}>
                    {t('invoices.upload')}
                </Button>
            </div>

            {/* Modal */}
            <InvoicesModal isVisible={isModalVisible} companies={companies} setIsVisible={setIsModalVisible} />

            {/* Main */}
            <section className={styles.wrapper}>
                <h4 className={styles.subtitle}>{t('invoices.invoiceList')}</h4>

                <section className={styles.filters}>
                    <InvoicesFilters
                        setSelectedCompany={setSelectedCompany}
                        setSelectedFile={setSelectedFile}
                        setSelectedInvoice={setBill}
                        getInvoices={getInvoices}
                        form={form}
                    />
                </section>
                <section className={styles.table}>
                    {bill ? (
                        <InvoicesDetails
                            bill={bill}
                            file={selectedFile!}
                            processInvoice={processInvoice}
                            onRefreshStatusInvoice={onRefreshStatusInvoice}
                        />
                    ) : (
                        ''
                    )}
                    {/* Table */}
                    <InvoiceRowsTable
                        invoiceRowsResults={bill?.billingRows}
                        onViewInvoiceRow={onViewInvoiceRow}
                        downloadFile={onDownloadFile}
                    />
                </section>
                {/* Actions */}
            </section>

            <InvoiceRowViewSideBar visible={visibleViewSidebar} setVisible={setVisibleViewSidebar} selectedInvoicedRows={selectedFlow} />
        </>
    );
};

export default Invoices;
