import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/global.scss';
import { BrowserRouter } from 'react-router-dom';
// import i18n (needs to be bundled ;))
import './js/tools/i18n';
/*import {Authenticate} from "react-oidc-client";
import {WebStorageStateStore, UserManager} from "oidc-client";

import {OidcSettings} from "./js/config/config";
import logo from "./assets/img/logo-viasat-group.png";
*/

//import reportWebVitals from './reportWebVitals';
/*
const mgr = new UserManager(OidcSettings);
const LoadingComponent:React.FC = ()=><div className="center"><img alt="Viasat Group" className="background-logo" src={logo}></img> </div>

async function logout(){
    await mgr.signoutRedirect();
}

mgr.events.addAccessTokenExpired(async() => {
    window.sessionStorage.clear();
    await mgr.signinRedirect();
})
*/

ReactDOM.render(
    //<React.StrictMode>
    /*<BrowserRouter>
        <Authenticate
            LoadingComponent={LoadingComponent}
            loginCompletePath="/signin-oidc"
            logoutPath="/signout-callback-oidc"
            userManager={mgr}
            userManagerSettings={{
                userStore: new WebStorageStateStore({
                    store: sessionStorage
                }),
                ...OidcSettings
            }}
        >
            <App logout={() => logout()} />
        </Authenticate>
        </BrowserRouter>
     */
    <Suspense fallback="">
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Suspense>,
    //</React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
