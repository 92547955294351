/* eslint-disable indent */
import { Button, Input, Layout, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Batch } from '../constants/types/batch';
import cronstrue from 'cronstrue/i18n';
import i18n from '../tools/i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertLevel } from '../constants/enum/alertLevel';
import { BatchState } from '../constants/enum/batchState';
import { SearchOutlined } from '@ant-design/icons';
import styles from 'assets/styles/modules/batches-manager.module.scss';
import Highlighter from 'react-highlight-words';
import { useStore } from 'store';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import BatchServices from 'js/data/batch-services';

const BatchesManager = () => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const [batches, setBatches] = useState<Batch[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<any>('');
    const setLoading = useStore((state) => state.setLoading);
    let searchInput: any;

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: (x: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={x.selectedKeys[0]}
                    onChange={(e) => x.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(x.selectedKeys, x.confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(x.selectedKeys, x.confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(x.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            x.confirm({ closeDropdown: false });
                            setSearchText(x.selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    ></Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: t('batchColumns.state'),
            dataIndex: 'stateDot',
            key: 1,
            filters: [
                {
                    text: t('batchState.active'),
                    value: 0,
                },
                {
                    text: t('batchState.disabled'),
                    value: 1,
                },
            ],
            onFilter: (value, record) => record.state == value,
        },
        {
            title: t('batchColumns.company'),
            dataIndex: 'company',
            key: 2,
            ...getColumnSearchProps('company'),
        },
        {
            title: t('batchColumns.name'),
            dataIndex: 'name',
            key: 3,
            ...getColumnSearchProps('name'),
        },
        {
            title: t('batchColumns.type'),
            dataIndex: 'type',
            key: 4,
        },
        {
            title: t('batchColumns.schedulation'),
            dataIndex: 'schedulation',
            key: 5,
        },
        {
            title: t('batchColumns.lastExecution'),
            dataIndex: 'lastExecution',
            key: 6,
            sorter: (a, b) => +new Date(a.lastExecutionDate) - +new Date(b.lastExecutionDate),
        },
        {
            title: t('batchColumns.lastExecutionDuration'),
            dataIndex: 'lastExecutionDuration',
            key: 7,
        },
        {
            title: t('batchColumns.result'),
            dataIndex: 'resultIcon',
            key: 8,
            filters: [
                {
                    text: t('batchResult.success'),
                    value: 0,
                },
                {
                    text: t('batchResult.warning'),
                    value: 1,
                },
                {
                    text: t('batchResult.error'),
                    value: 2,
                },
            ],
            onFilter: (value, record) => record.result == value,
        },
        {
            title: t('batchColumns.resultMessage'),
            dataIndex: 'resultMessage',
            key: 9,
        },
    ];

    const mapResultIcons = (batchResultId: number) => {
        switch (batchResultId) {
            case AlertLevel.Success:
                return (
                    <FontAwesomeIcon icon={faCheckCircle} title={AlertLevel[batchResultId]} size="lg" className={styles['green-icon']} />
                );

            case AlertLevel.Warning:
                return (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        title={AlertLevel[batchResultId]}
                        size="lg"
                        className={styles['yellow-icon']}
                    />
                );

            case AlertLevel.Error:
                return <FontAwesomeIcon icon={faTimesCircle} title={AlertLevel[batchResultId]} size="lg" className={styles['red-icon']} />;

            default:
                return '';
        }
    };

    const mapBatchState = (batchStateId: number) => {
        switch (batchStateId) {
            case BatchState.Active:
                return <FontAwesomeIcon icon={faCircle} size="xs" className={styles['green-icon']} />;

            case BatchState.Disabled:
                return <FontAwesomeIcon icon={faCircle} size="xs" className={styles['gray-icon']} />;

            default:
                return '';
        }
    };

    const mapLastExecutionDate = (lastExecution: string, expectedLastExecution: string) => {
        const lastExDate = lastExecution ? new Date(lastExecution) : null;
        const lastExExpectedDate = expectedLastExecution ? new Date(expectedLastExecution) : null;

        if (lastExDate && lastExExpectedDate && lastExDate !== lastExExpectedDate) {
            return (
                <>
                    <span className={styles['last-execution']}>
                        {`${moment(lastExDate).format(DateFormats[currentLanguage])} ${lastExDate.toLocaleTimeString('en-GB')}`}
                    </span>
                    <span className={styles['last-expected-execution']}>
                        {`(${t('commonClass.exp')} ${moment(lastExExpectedDate).format(
                            DateFormats[currentLanguage]
                        )} ${lastExExpectedDate.toLocaleTimeString('en-GB')})`}
                    </span>
                </>
            );
        }

        if (lastExDate) {
            return (
                <span className={styles['last-execution']}>{`${moment(lastExDate).format(
                    DateFormats[currentLanguage]
                )} ${lastExDate.toLocaleTimeString()}`}</span>
            );
        }

        return '';
    };

    useEffect(() => {
        const getBetches = async () => {
            setLoading(true);
            setBatches(await BatchServices.getBatches());
            setLoading(false);
        };
        getBetches();
        // eslint-disable-next-line
    }, []);

    return (
        <section className={styles.table}>
            <Layout className="site-layout-background home-container center">
                <Table
                    size="small"
                    columns={columns}
                    scroll={{ x: 800 }}
                    dataSource={batches.map((batch, index) => ({
                        ...batch,
                        key: index,
                        lastExecution: mapLastExecutionDate(batch.lastExecutionDate, batch.previousSchedule),
                        schedulation: batch.schedulation
                            ? cronstrue.toString(batch.schedulation, {
                                  locale: currentLanguage.split('-')[0],
                                  use24HourTimeFormat: true,
                                  verbose: true,
                              })
                            : '',
                        resultIcon: mapResultIcons(batch.result),
                        lastExecutionDuration: batch.lastExecutionDuration?.substring(0, 11),
                        stateDot: mapBatchState(batch.state),
                    }))}
                ></Table>
            </Layout>
        </section>
    );
};

export default BatchesManager;
