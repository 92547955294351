import React, { useState } from 'react';
import styles from 'assets/styles/modules/policies-management.module.scss';
import ReportServices from 'js/data/report-services';
import ReportsTable from 'js/components/reports/reports-table';
import ReportsFilters from 'js/components/reports/reports-filters';
import moment from 'moment';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { FileType } from 'js/constants/enum/fileType';

const ReportsManagement = () => {
    const { t } = useTranslation();
    const setLoading = useStore((state) => state.setLoading);
    const [form] = Form.useForm();
    const [reportData, setReportData] = useState<any[]>([]);
    const [dateFilter, setDateFilter] = useState<[string, string]>();
    const [yearFilter, setYearFilter] = useState<any>();
    const [yearMonthFilter, setYearMonthFilter] = useState<any>();
    const [selectedReport, setSelectedReport] = useState<any>();

    const onDownloadFile = async (event: any, fileType: FileType) => {
        const formValues = form.getFieldsValue();
        formValues.rangeDate = dateFilter;
        formValues.year = yearFilter;
        formValues.yearMonth = yearMonthFilter;
        const formatDate = 'DD-MM-YYYY';
        const todayDate = new Date();

        let fileName = '';
        // TODO: da parametrizzare su DB
        switch (parseInt(selectedReport)) {
            case 327:
                fileName = 'NuovePolizzeCattolica_';
                break;
            case 328:
                fileName = 'NuovePolizzeGenerali_';
                break;
            case 329:
                fileName = 'NuovePolizzeGenertel_';
                break;
            case 330:
                fileName = 'NuovePolizzeGencat_';
                break;
            case 331:
                fileName = 'DettaglioInstallazioni_';
                break;
            case 334:
                fileName = 'LivelliDiServizio_';
                break;
            case 336:
                fileName = 'InstallazioniTrimestraliGenerali_';
                break;
            case 337:
                fileName = 'InstallazioniTrimestraliCattolica_';
                break;
            case 338:
                fileName = 'InstallazioniTrimestraliGenertel_';
                break;
            case 343:
                fileName = 'KpiDettaglioInstallazioniGenerali_';
                break;
            case 344:
                fileName = 'KpiInstallazioniGenerali_';
                break;
        }

        event.stopPropagation();
        setLoading(true);
        await ReportServices.getReportDataFile(fileName + moment(todayDate).format(formatDate), fileType, formValues);
        setLoading(false);
    };

    return (
        <>
            <h2 className={styles.title}>{t('commonClass.insuranceReports')}</h2>
            <section className={styles.filters}>
                <ReportsFilters
                    setSelectedReport={setSelectedReport}
                    setReportData={setReportData}
                    setDateFilter={setDateFilter}
                    setYearFilter={setYearFilter}
                    setYearMonthFilter={setYearMonthFilter}
                    form={form}
                />
            </section>
            <ReportsTable reportResult={reportData} selectedReport={selectedReport} downloadFile={onDownloadFile} />
        </>
    );
}

export default ReportsManagement;