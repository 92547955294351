import i18next from 'i18next';
import i18n from 'js/tools/i18n';

export let INVOICE_ROWS_PROPERTIES;

export const fieldsInvoiceProperty = [
    { key: 'type', type: 'text', readonly: true, order: 10 },
    { key: 'rowNumber', type: 'text', readonly: true, order: 20 },
    { key: 'licensePlate', type: 'text', readonly: true, order: 30 },
    { key: 'agencyCode',type: 'text', readonly: true, order: 40 },
    { key: 'agencyName', type: 'text',readonly: true, order: 50 },
    { key: 'policyNumber',type: 'text', readonly: true, order: 60 },
    { key: 'voucherNumber', type: 'text',readonly: true, order: 70 },
    { key: 'movementId',type: 'text', readonly: true, order: 80 },
    { key: 'operationDate',type: 'date', readonly: true, order: 90 },
    { key: 'stateCode',type: 'text', readonly: true, order: 100 },
    { key: 'oldPolicyNumber', type: 'text',readonly: true, order: 110},
    { key: 'oldLicensePlate', type: 'text',readonly: true, order: 120 },
    { key: 'effectiveOperationDate', type: 'date',readonly: true, order: 130 },
    { key: 'firstInstallationDate',type: 'date', readonly: true, order: 140 },
    { key: 'deviceActivationDate',type: 'date', readonly: true, order: 150 },
    { key: 'deviceDeactivationDate',type: 'date', readonly: true, order: 160 },
    { key: 'startServiceDate',type: 'date', readonly: true, order: 170 },
    { key: 'endServiceDate',type: 'date', readonly: true, order: 190 },
    { key: 'installationDate',type: 'date', readonly: true, order: 200 },
    { key: 'uninstallDate',type: 'date', readonly: true, order: 210 },
    { key: 'startBillingDate',type: 'date', readonly: true, order: 220 },
    { key: 'endBillingDate',type: 'date', readonly: true, order: 230 },
    { key: 'customerFiscalId',type: 'text', readonly: true, order: 240 },
    { key: 'customerName',type: 'text', readonly: true, order: 250 },
    { key: 'billedDays',type: 'text', readonly: true, order: 260 },
    { key: 'product',type: 'text', readonly: true, order: 270 },
    { key: 'deviceType',type: 'text', readonly: true, order: 280 },
    { key: 'deviceId',type: 'text', readonly: true, order: 280 },
    { key: 'performanceDetail',type: 'text', readonly: true, order: 290 },
    { key: 'amount',type: 'text', readonly: true, order: 300 },
    { key: 'taxable',type: 'text', readonly: true, order: 310 },
    { key: 'vat',type: 'text', readonly: true, order: 320 },
    { key: 'note',type: 'text', readonly: true, order: 330 },

    
];

i18next.on('languageChanged init', function () {

    INVOICE_ROWS_PROPERTIES = new Map([
        ['type', i18n.t('invoices.type')],
        ['rowNumber', i18n.t('invoices.invoiceRows')],
        ['licensePlate', i18n.t('invoices.licensePlate')],
        ['agencyCode', i18n.t('invoices.agencyCode')],
        ['agencyName', i18n.t('invoices.agencyName')],
        ['policyNumber', i18n.t('invoices.policyNumber')],
        ['voucherNumber', i18n.t('invoices.voucherNumber')],
        ['movementId', i18n.t('invoices.movementId')],
        ['operationDate', i18n.t('invoices.operationDate')],
        ['stateCode', i18n.t('invoices.stateCode')],
        ['oldPolicyNumber', i18n.t('invoices.oldPolicyNumber')], 
        ['oldLicensePlate', i18n.t('invoices.oldLicensePlate')],
        ['effectiveOperationDate', i18n.t('invoices.effectiveOperationDate')],
        ['firstInstallationDate', i18n.t('invoices.firstInstallationDate')],
        ['deviceActivationDate', i18n.t('invoices.deviceActivationDate')],
        ['deviceDeactivationDate', i18n.t('invoices.deviceDeactivationDate')],
        ['startServiceDate', i18n.t('invoices.startServiceDate')],
        ['endServiceDate', i18n.t('invoices.endServiceDate')],
        ['installationDate', i18n.t('invoices.installationDate')],
        ['uninstallDate', i18n.t('invoices.uninstallDate')],
        ['startBillingDate', i18n.t('invoices.startBillingDate')],
        ['endBillingDate', i18n.t('invoices.endBillingDate')],
        ['customerFiscalId', i18n.t('invoices.customerFiscalId')],
        ['customerName', i18n.t('invoices.customerName')],
        ['billedDays', i18n.t('invoices.billedDays')],
        ['product', i18n.t('invoices.product')],
        ['deviceType', i18n.t('invoices.deviceType')],
        ['deviceId', i18n.t('invoices.deviceId')],
        ['performanceDetail', i18n.t('invoices.performanceDetail')],
        ['amount', i18n.t('invoices.amount')],
        ['taxable', i18n.t('invoices.taxable')],
        ['vat', i18n.t('invoices.vat')],
        ['note', i18n.t('invoices.notes')],
    ]);
});
