import { Policy, PolicySearch } from 'js/constants/types/policy';
import UtilsServices from 'js/tools/utils-services';
import { useStore } from 'store';

/*
 *  Shared variables and methods
 */
const token = useStore.getState().user?.token;
const headers = new Headers({ Authorization: `Bearer ${token}`, 'content-type': 'application/json' });
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

useStore.subscribe((state) => {
    headers.set('Authorization', `Bearer ${state.user?.token}`);
});

export default class PolicyServices {
    public static getPolicies = async (search: PolicySearch): Promise<Policy[]> => {
        const response = await fetch(
            `${baseUrl}/API/Policies/GetPolicies?companyId=${search.companyId}${
                search.policyNumber ? '&policyNumber=' + search.policyNumber : ''
            }${search.customerName ? '&customerName=' + search.customerName : ''}${
                search.licensePlate ? '&licensePlate=' + search.licensePlate : ''
            }${search.originVoucher ? '&voucher=' + search.originVoucher : ''}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static getPolicy = async (companyId: number, policyNumber: string): Promise<Policy> => {
        const response = await fetch(
            `${baseUrl}/API/Policies/GetPolicies?companyId=${companyId}&policyNumber=${policyNumber}}`,
            { headers }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };

    public static updateExpirationDate = async (companyId: number, contractId: string, limitActivatioDate : string): Promise<void | Response> => {
        const response = await fetch(
            `${baseUrl}/API/Policies/UpdInstExpirationDate?companyId=${companyId}&contractId=${contractId}&limitActivatioDate=${limitActivatioDate}`,
            {
                method: 'PUT',
                headers,
            }
        );
        const data = await UtilsServices.processResponse(response);

        return data;
    };
}
