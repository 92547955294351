import React from 'react';
import styles from 'assets/styles/modules/invoices.module.scss';
import { Button, Table, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'js/tools/i18n';
import moment from 'moment';
import { DateFormats } from 'js/config/config';
import { BillRow } from 'js/constants/types/bill';
import { DashOutlined } from '@ant-design/icons';
import DownoadFileButtons from '../donwload-file';
import { FileType } from 'js/constants/enum/fileType';

interface IProps {
    onViewInvoiceRow: any;
    invoiceRowsResults?: BillRow[];
    downloadFile: (event, fileType: FileType) => Promise<void>;
}

const InvoiceRowsTable = (props: IProps) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    const buttonsDownload = [
        {
            type: FileType.Csv,
            text: 'csv',
            disabled: props.invoiceRowsResults ? false : true,
            tooltip: t('commonClass.downloadCsv'),
            order: 10,
            key: 1,
        },
        {
            type:FileType.Excel,
            text: 'xlsx',
            disabled: props.invoiceRowsResults ? false : true,
            tooltip: t('commonClass.downloadXlsx'),
            order: 20,
            key: 2,
        },
    ];

    const columns = [
        {
            key: 1,
            title: t('invoices.policyNumber'),
            dataIndex: 'policyNumber',
        },
        {
            key: 2,
            title: t('invoices.voucherNumber'),
            dataIndex: 'voucherNumber',
        },
        {
            key: 3,
            title: t('invoices.licensePlate'),
            dataIndex: 'licensePlate',
        },
        {
            key: 4,
            title: t('invoices.performanceDetail'),
            dataIndex: 'performanceDetail',
        },
        {
            key: 5,
            title: t('invoices.operationDate'),
            dataIndex: 'operationDate',
        },
        {
            key: 6,
            title: t('invoices.stateCode'),
            dataIndex: 'stateCode',
        },
        {
            key: 7,
            title: t('invoices.oldPolicyNumber'),
            dataIndex: 'oldPolicyNumber',
        },
        {
            key: 8,
            title: t('invoices.oldLicensePlate'),
            dataIndex: 'oldLicensePlate',
        },
        {
            key: 9,
            title: t('invoices.firstInstallationDate'),
            dataIndex: 'firstInstallationDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 10,
            title: t('invoices.deviceActivationDate'),
            dataIndex: 'deviceActivationDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 11,
            title: t('invoices.startServiceDate'),
            dataIndex: 'startServiceDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 12,
            title: t('invoices.endServiceDate'),
            dataIndex: 'endServiceDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 13,
            title: t('invoices.installationDate'),
            dataIndex: 'installationDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 14,
            title: t('invoices.uninstallDate'),
            dataIndex: 'uninstallDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 15,
            title: t('invoices.startBillingDate'),
            dataIndex: 'startBillingDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 16,
            title: t('invoices.endBillingDate'),
            dataIndex: 'endBillingDate',
            render: (date: string) => (date ? moment(date, 'DD/MM/YYYY').format(DateFormats[currentLanguage]) : null),
        },
        {
            key: 17,
            title: t('invoices.billedDays'),
            dataIndex: 'billedDays',
        },
        {
            key: 18,
            title: t('invoices.product'),
            dataIndex: 'product',
        },
        {
            key: 19,
            title: t('invoices.deviceType'),
            dataIndex: 'deviceType',
        },
        {
            key: 20,
            title: t('invoices.amount'),
            dataIndex: 'amount',
        },
        {
            key: 21,
            title: t('invoices.taxable'),
            dataIndex: 'taxable',
        },
        {
            key: 22,
            title: t('invoices.vat'),
            dataIndex: 'vat',
        },
        {
            title: t('commonClass.view'),
            key: 19,
            align: 'center' as const,
            render: (record: BillRow) => (
                <Tooltip placement="left" title={t('commonClass.viewExtraInfo')}>
                    <Space size="middle">
                        <Button onClick={(event) => props.onViewInvoiceRow(event, record)} type="primary" shape="circle" icon={<DashOutlined />} />
                    </Space>
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            <DownoadFileButtons buttonsProperty={buttonsDownload} downloadFile={props.downloadFile} />
            <section className={styles.table}>
                <h4 className={styles['table-title']}>{t('invoices.invoiceRows')}</h4>
                <Table
                    columns={columns}
                    dataSource={props.invoiceRowsResults}
                    rowKey="rowNumber"
                    pagination={{ pageSize: 10, showSizeChanger: false, position: ['bottomCenter'] }}
                    size="small"
                    scroll={{ x: 800 }}
                    footer={() => (
                        <div className={styles.footer}>
                            {t('flows.matchesTotal')}: {props.invoiceRowsResults?.length || 0}
                        </div>
                    )}
                ></Table>
            </section>
        </>
    );
};

export default InvoiceRowsTable;
